import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const getAuth = (state) => state.containers.auth;

// Need to use .get, beucase reducer defaulState was created by using ImmutableJS
export const getEmail = (state) => getAuth(state).getIn(['userInfo', 'email']);
export const getLoggedIn = (state) => getAuth(state).get('loggedIn') && getAuth(state).get('bearerAuthSuccess');
export const getLogging = (state) => getAuth(state).get('logging') || getAuth(state).get('bearerAuthLogging');
export const getLoginError = (state) => getAuth(state).get('loginError') || getAuth(state).get('bearerAuthError');
export const getUserId = (state) => getAuth(state).get('userId');
export const getUserInfo = createSelector(
  (state) => getAuth(state).get('userInfo'),
  (state) => getAuth(state).get('userData'),
  (userInfo, userData) => (userInfo ? userInfo.merge(userData) : userData),
);
export const getAddresses = (state) => getAuth(state).getIn(['userInfo', 'addresses']);
export const getTouchedAddresses = (state) => {
  const addressIds = getAuth(state).getIn(['touched', 'addresses']);
  const addresses = getAuth(state).getIn(['userInfo', 'addresses']) || [];
  return addresses.filter((address) => addressIds.has(address.get('id')));
}
export const getAddress = (state, id) => (getAddresses(state) || []).find(s => s.get('id') === id);
export const getContacts = (state) => getAuth(state).getIn(['userInfo', 'contacts']);
export const getTouchedContacts = (state) => {
  const contactIds = getAuth(state).getIn(['touched', 'contacts']);
  const contacts = getAuth(state).getIn(['userInfo', 'contacts']) || [];
  return contacts.filter((contact) => contactIds.has(contact.get('id')));
}
export const getContact = (state, id) => (getContacts(state) || []).find(s => s.get('id') === id);
export const getAlternates = (state) => getAuth(state).getIn(['userInfo', 'alternates']);
export const getAlternate = (state, id) => getAlternates(state).find(s => s.get('id') === id);
export const getSendError = (state) => getAuth(state).get('sendError');
export const getSending = (state) => getAuth(state).get('sending');
export const getSendComplete = (state) => getAuth(state).get('sendComplete');
export const getResetError = (state) => getAuth(state).get('resetError');
export const getResetting = (state) => getAuth(state).get('resetting');
export const getResetComplete = (state) => getAuth(state).get('resetComplete');
export const getAccountId = (state) => {
  const accounts = getAuth(state).get('accounts') || new List([new Map()]);
  return accounts.find((account) => account.get('isPrimary'), null, accounts.first()).get('id');
}
export const getMagicKey = (state) => getAuth(state).get('magicKey');
