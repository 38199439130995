import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { result, find } from 'lodash';
import { UsaStates } from 'usa-states';
import GoogleMap from 'google-map-react';
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
  withStyles,
  IconButton,
} from 'material-ui';
import { Place } from 'material-ui-icons';
import Loading from '../../components/Loading';
import UnitIcon from '../../components/UnitIcon';
import { fetchFacility } from './reducer';
import { getError, getFacility, getLoading } from './selectors';
import { setMapModalOpen } from '../../containers/AppWrapper/reducer';
import './styles.css';
import { currency } from '../../utils/format';
import { getFacilities/* , getLocation */ } from '../SearchMap/selectors';
import MapControl from '../../components/MapControl';

const GoogleMapConfig = {
  key: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: 'places',
};
const { states } = new UsaStates();
const styles = (theme) => ({
  arrowRight: {
    display: 'inline-block',
    marginLeft: 12,
    marginRight: 12,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  homeButton: {
    backgroundColor: '#FFFFFF',
    width: 40,
    height: 40,
    margin: 10,
    borderRadius: 2,
    boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
    '&:hover': {
      color: '#000000',
    },
  },
  paperHeader: {
    padding: '8px 40px',
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
  },
  rightPaper: {
  },
  unitBlock: {
    padding: '20px 8px 30px',
  },
});

class Facility extends Component {
  state = {
    map: null,
    maps: null,
  };

  componentDidMount() {
    const {
      actions,
      title,
    } = this.props;
    document.title = title;

    const { facilityId } = this.props.match.params;
    actions.fetchFacility({ facility_id: facilityId });
  }

  componentWillReceiveProps(props) {
    const {
      facilityId,
    } = props.match.params;
    if (facilityId !== this.props.match.params.facilityId) {
      this.props.actions.fetchFacility({ facility_id: facilityId })
    } else if (this.props.loading && !props.loading && props.facility) {
      window.gtag('event', 'view_item_list', {
        item_list_id: facilityId,
        item_list_name: props.facility.site_code || props.facility.store_number,
        items: props.facility.available_units.map((u, index) => ({
          item_id: `${u.facility_id}_${u.unit_type_id}_${u.length}_${u.width}`,
          item_name: `${u.unit_type_name} ${u.length} x ${u.width}`,
          index,
          item_category: 'Unit',
          item_category2: u.unit_type_name,
          item_variant: `${u.length} x ${u.width}`,
          coupon: u.promotion_id,
        })),
      });
    }
  }

  onGoogleApiLoaded = ({ map, maps }) => {
    const loc = this.props.facility.location;
    this.marker = new maps.Marker({ map, position: loc });

    maps.event.addListener(this.marker, 'click', () => {
      const bounds = new maps.Circle({
        center: loc,
        radius: 50,
      }).getBounds();
      map.fitBounds(bounds);
    });

    this.setState({ map, maps });

    /* this.destMarker = new maps.Marker({
      icon: {
        url: Logo,
        scaledSize: new maps.Size(25, 25),
        anchor: new maps.Point(12, 12),
      },
      map,
      position: this.props.facility.location,
      title: 'Red Dot Storage',
    });

    const loc = this.props.userLocation;
    if (loc) {
      this.sourceMarker = new maps.Marker({
        map,
        position: loc.position,
        title: loc.title,
      });

      this.calculateAndDisplayRoute(
        maps,
        new maps.DirectionsService(),
        new maps.DirectionsRenderer({ map, suppressMarkers: true }),
        loc.position,
        this.props.facility.location,
      );
    } */
  }

  getFullStateName = (initial) => {
    const state = find(states, (e) => e.abbreviation === initial);
    return state && state.name;
  }

  /* calculateAndDisplayRoute = (maps, directionsService, directionsDisplay, pointA, pointB) => {
    directionsService.route({
      origin: pointA,
      destination: pointB,
      avoidTolls: true,
      avoidHighways: false,
      travelMode: maps.TravelMode.DRIVING,
    }, (response, status) => {
      if (status === maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  } */

  facilityInfo = () => {
    const {
      classes,
      facility,
    } = this.props;
    const add = facility.default_address || {};

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography type="title" gutterBottom>
            <span>{this.getFullStateName(add.state)} → {add.city} Location</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography type="subheading" color="textSecondary" gutterBottom>
            <i>Red Dot Store No. {facility.store_number}</i>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            raised
            fullWidth
            color="secondary"
            onClick={() => this.props.actions.setMapModalOpen(true)}
          >
            Change Location
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textSecondary">
            { facility.description || '#' }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography type="subheading">
            <b>Call us at — </b>
            <a className="link" href={`tel:${facility.business_phone}`}>{facility.business_phone}</a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography type="subheading" gutterBottom>Call Hours</Typography>
          <Typography>Mon-Sat: {facility.call_hours[0]}</Typography>
          <Typography>Sun: {facility.call_hours[1]}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography type="subheading" gutterBottom>Address</Typography>
          <a className="link" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(`${add.street}, ${add.city}, ${add.state} ${add.zip}`)}`} rel="noreferrer" target="_blank" title="Get Directions">
            <Typography color="secondary">{add.street}</Typography>
            <Typography color="secondary">{add.city} {add.state} {add.zip}</Typography>
          </a>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography type="subheading" gutterBottom>Email</Typography>
          <Typography color="secondary"><a className="link" href={`mailto:${facility.email}`} rel="noreferrer" target="_blank">{facility.email}</a></Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography type="subheading" gutterBottom>Gate Hours</Typography>
          <Typography>24 hour access</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="app-googlemap-wrapper">
            <div className="app-googlemap">
              <GoogleMap
                defaultCenter={facility.location}
                defaultZoom={13}
                bootstrapURLKeys={GoogleMapConfig}
                onGoogleApiLoaded={this.onGoogleApiLoaded}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  gestureHandling: 'cooperative',
                  mapTypeControl: true,
                  mapTypeControlOptions: {
                    mapTypeIds: ['roadmap', 'hybrid', 'satellite'],
                  },
                  streetViewControl: true,
                  tilt: 45,
                }}
              >
                <MapControl
                  map={this.state.map}
                  position={this.state.maps && this.state.maps.ControlPosition.RIGHT_TOP}
                >
                  <IconButton
                    className={classes.homeButton}
                    onClick={() => {
                      this.state.map.setOptions({
                        center: facility.location,
                        zoom: 13,
                        mapTypeId: 'roadmap',
                      })
                    }}
                    title="Zoom to Facility"
                  >
                    <Place />
                  </IconButton>
                </MapControl>
              </GoogleMap>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  unitsList = () => {
    const {
      classes,
      facility,
    } = this.props;
    return (
      <Paper className="right-paper">
        <Grid container className={classes.paperHeader}>
          <Typography type="subheading" style={{ color: 'white' }}> Facility Units Available </Typography>
          { facility.available_units_count ? <Typography type="subheading" style={{ color: 'white' }}>{facility.available_units_count} Total</Typography> : null}
        </Grid>
        <Grid container className="units-container">
          { facility.available_units.map((u, i) => this.renderUnit(u, i)) }
        </Grid>
      </Paper>
    )
  }

  renderUnit = (unit, index) => {
    const { classes, facility, match } = this.props;

    const reservationUrl = `/reservation/${unit.first_available_unit_id}/userInfo`;

    return (
      <Grid item xs={12} key={index}>
        <Grid alignItems="center" container className={classes.unitBlock}>
          <Grid item xs={2}>
            <UnitIcon width={unit.width} length={unit.length} />
          </Grid>
          <Grid item xs={3}>
            <Hidden xsDown>
              <Typography type="title">{`${unit.width}' x ${unit.length}'`}</Typography>
            </Hidden>
            <Hidden smUp>
              <Typography type="subheading">{`${unit.width}' x ${unit.length}'`}</Typography>
            </Hidden>
            <Typography color="textSecondary">{unit.unit_type_name || unit.unit_type}</Typography>
            <Typography color="textSecondary">{unit.width * unit.length} sq. ft.</Typography>
          </Grid>
          <Grid item xs={4}>
            <Hidden xsDown>
              <Typography type="headline" align="center">{currency(unit.price)}</Typography>
            </Hidden>
            <Hidden smUp>
              <Typography type="subheading" align="center">{currency(unit.price)}</Typography>
            </Hidden>
            <Typography color="textSecondary" align="center">{unit.price != null ? 'per month' : ''}</Typography>
            <Typography color="secondary" align="center">{unit.promotion_description}</Typography>
          </Grid>
          <Grid className={classes.buttonContainer} item xs={3}>
            {
              unit.first_available_unit_id ?
                <Button
                  raised
                  color="secondary"
                  onClick={() => {
                    window.gtag('event', 'select_item', {
                      item_list_id: match.params.facilityId,
                      item_list_name: facility.site_code || facility.store_number,
                      items: [unit],
                    });
                    this.props.history.push(reservationUrl, { skippable: true });
                  }}
                >
                  Rent Now
                </Button> :
                <Typography color="textSecondary" align="center"><b>Call for availability</b></Typography>
            }
          </Grid>
        </Grid>
        {
          index < facility.available_units.length - 1 &&
          <Divider />
        }
      </Grid>
    )
  }

  render() {
    const { error, facility, loading } = this.props;
    if (!facility || loading) {
      if (error) {
        return (
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            spacing={24}
          >
            <Typography type="display1" align="center">Facility could not be loaded! Please check your internet connection and refresh the page to try again.</Typography>
          </Grid>
        );
      }

      return (
        <Loading />
      );
    }

    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={24}
      >
        <Grid item lg={3} md={4} sm={12} xs={12}>
          {this.facilityInfo()}
        </Grid>
        <Grid item lg={5} md={8} sm={12} xs={12}>
          {this.unitsList()}
        </Grid>
      </Grid>
    );
  }
}

Facility.defaultProps = {
  error: null,
  facility: null,
  loading: false,
  title: 'Facility',
  // userLocation: undefined,
};

Facility.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  facility: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool,
  match: PropTypes.object.isRequired,
  title: PropTypes.string,
  /* userLocation: PropTypes.shape({
    bounds: PropTypes.shape({
      east: PropTypes.number,
      north: PropTypes.number,
      south: PropTypes.number,
      west: PropTypes.number,
    }),
    padding: PropTypes.number,
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    title: PropTypes.string,
    zoom: PropTypes.number,
  }), */
};

const mapStateToProps = (state) => {
  const facility = result(getFacility(state), 'toJS', null);
  const facilities = result(getFacilities(state), 'toJS', null);
  if (facility && facilities && facilities.length > 0) {
    const currentFacility = facilities.find((f) => f.id === facility.id);
    if (currentFacility) facility.available_units_count = currentFacility.available_units_count;
  }

  return {
    error: getError(state),
    facility,
    loading: getLoading(state),
    // userLocation: getLocation(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFacility,
    setMapModalOpen,
  }, dispatch),
});

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facility)));
