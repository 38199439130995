import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Grid,
  Modal,
  Typography,
  withStyles,
} from 'material-ui';

import { getLogging, getLoggedIn } from './selectors';
import { getReturnUrl } from '../AppWrapper/selectors';
import LoginForm from './loginForm';
import AppLogo from '../../components/Logo';
import Loading from '../../components/Loading';
import ForgotPasswordForm from './forgotPasswordForm';

import './styles.css';
import { routePropTypes } from '../../utils/routes';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    padding: '16px',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
});

class Auth extends Component {
  state = {
    openSendEmailModal: false,
  }

  // This only fires on 1st render of URL
  componentWillMount() {
    // If logged in Go to default route
    if (this.props.loggedIn) {
      this.props.history.replace('/units');
    }
  }

  componentDidMount() {
    document.title = this.props.title;
  }

  // This only fires on update (after 1st render of URL)
  shouldComponentUpdate(props) {
    // If logged in Go to return url
    if (!this.props.loggedIn && props.loggedIn) {
      const returnUrl = props.returnUrl || '/units';
      if (typeof returnUrl === 'string') {
        props.history.replace(returnUrl);
      } else if (typeof returnUrl === 'object') {
        props.history.replace(returnUrl.url, returnUrl.state);
      } else if (returnUrl) {
        props.history.goBack();
      }

      return false;
    }

    return true;
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <Grid
        container
        direction="column"
        spacing={0}
        wrap="nowrap"
        justify="center"
        alignItems="center"
        className="minusMargin"
      >
        <div className="login-logo">
          <AppLogo />
        </div>
        <Grid item className="login-title">
          <h1 className="login-heading">Customer Login</h1>
          <Typography onClick={() => this.setState({ openSendEmailModal: true })}>
            First Time Logging In?
          </Typography>
        </Grid>
        <Grid item>
          <Modal open={this.props.logging}>
            <Loading />
          </Modal>
          <LoginForm />
        </Grid>
        <div className="login-footer">
          <Typography align="center">Copyright {moment().year()} Red Dot Storage. All Rights Reserved</Typography>
        </div>
        <Modal
          open={this.state.openSendEmailModal}
          onClose={() => this.setState({ openSendEmailModal: false })}
        >
          <div
            className={classes.paper}
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <ForgotPasswordForm
              isFirst
              onClose={() => this.setState({ openSendEmailModal: false })}
            />
          </div>
        </Modal>
      </Grid>
    );
  }
}

Auth.defaultProps = {
  logging: false,
  loggedIn: false,
  returnUrl: false,
  title: 'Login',
};

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
  logging: PropTypes.bool,
  loggedIn: PropTypes.bool,
  returnUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]),
  title: PropTypes.string,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  logging: getLogging(state),
  loggedIn: getLoggedIn(state),
  returnUrl: getReturnUrl(state),
});

export default withStyles(styles)(connect(mapStateToProps)(Auth));
