import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'material-ui/Grid';

import './styles.css';

const AppLogo = ({ size }) => (
  <div className={`logo ${size}`}>
    <Grid
      container
      alignItems="center"
      direction="row"
      justify="center"
      spacing={0}
    >
      <Grid item>
        <a href="https://www.reddotstorage.com">
          <img src="/logo.png" alt="RED DOT STORAGE" />
        </a>
      </Grid>
    </Grid>
  </div>
);
AppLogo.propTypes = {
  size: PropTypes.oneOf(['', 'large']),
};
AppLogo.defaultProps = {
  size: '',
};

export default AppLogo;
