import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from 'material-ui';
import { History, KeyboardArrowLeft, KeyboardArrowRight } from 'material-ui-icons';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import { result } from 'lodash';

import UnitSelector from './unitSelector';
import PaymentForm from './PaymentMethods/paymentForm';
import Loading from '../../components/Loading';
import CurrentBill from './CurrentBill';
import MakePayment from './CurrentBill/makePayment';
import PaymentMethods from './PaymentMethods';
import BillingHistory from './BillingHistory';
import {
  getBillingData,
  getExpectedMonthlyCharge,
  getInvoices,
  getLineItems,
  getLoading,
  getLoaded,
} from './selectors';
import {
  getLoaded as getUnitsLoaded,
  getOccupiedFacilities,
  getSelectedFacility,
  getSelectedUnit,
  getFacilityOccupiedUnits,
} from '../MyUnits/selectors';
import { updateSelectedFacility, updateSelectedUnit, fetchPaymentAuthorizationMessageThunk } from './reducer';
import FileAltIcon from '../../svgs/file-alt';
import CreditCardIcon from '../../svgs/credit-card';
import { currency } from '../../utils/format';
import UnitIcon from '../../components/UnitIcon';
import './styles.css';
import { routePropTypes } from '../../utils/routes';

const menus = [{
  text: 'Current Bill',
  icon: <FileAltIcon fill="rgba(0, 0, 0, 0.54)" />,
  url: '/billing',
}, {
  text: 'Payment Methods',
  icon: <CreditCardIcon fill="rgba(0, 0, 0, 0.54)" />,
  url: '/billing/paymentMethods',
}, {
  text: 'Billing History',
  icon: <History />,
  url: '/billing/billingHistory',
}];

const styles = (theme) => ({
  /*
    Column logic:
    >= 1280 px, display all 4 columns on one line
    600 - 960 px UI switches to vertical layout, so we can still display on one line
    < 600 px or 960 - 1280 px switch to 2 column layout to save space
  */

  col1: {
    width: '50%',
    [theme.breakpoints.up('lg')]: {
      width: '16%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '16%',
    },
  },
  col2: {
    width: '50%',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '28%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '28%',
    },
  },
  col3: {
    alignSelf: 'center',
    width: '50%',
    [theme.breakpoints.up('lg')]: {
      width: '28%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '28%',
    },
  },
  col4: {
    alignSelf: 'center',
    width: '50%',
    [theme.breakpoints.up('lg')]: {
      width: '28%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '28%',
    },
  },
  facilityTitle: {
    fontWeight: 'bold',
  },
  flex: {
    flex: '0 0 auto',
  },
  floatRight: {
    float: 'right',
  },
  gutterBottom: {
    marginBottom: '3em',
  },
  header: {
    borderBottom: '1px solid lightgrey',
  },
  helpText: {
    textDecoration: 'underline',
    cursor: 'help',
  },
  navigationButton: {
    height: '24px',
  },
  tooltip: {
    alignItems: 'baseline',
    columnGap: '1em',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    maxWidth: '335px',
  },
  unitBlock: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 800,
    padding: '15px 30px 10px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 8,
    },
  },
});

class Billing extends Component {
  componentDidMount() {
    this.props.actions.fetchPaymentAuthorizationMessage();
  }

  shouldComponentUpdate(props) {
    // If Account Balance changes for a Unit that means a Payment was made;
    // return back to default Billing page
    if (props.unit && this.props.unit && props.unit.id !== this.props.unit.id) {
      this.ignoreDataUpdate = true;
    }

    if (props.billingData !== this.props.billingData) {
      if (props.billingData && this.props.billingData && props.billingData.get('account_balance') !== this.props.billingData.get('account_balance')
        && !this.ignoreDataUpdate && !props.location.pathname.endsWith('/billing')) {
        props.history.push('/billing');
        return false;
      }
      this.ignoreDataUpdate = false;
    }

    return true;
  }

  handleMenuItemClick = (event, selected) => {
    this.props.history.push(selected);
  };

  handleCancelPayment = () => {
    this.props.history.goBack();
  };

  handleNextClick = () => {
    const {
      actions,
      facilities,
      facility,
      occupiedUnits,
      unit,
    } = this.props;

    const unitIndex = occupiedUnits.findIndex((u) => u.get('id') === unit.id);
    if (unitIndex < occupiedUnits.size - 1) {
      actions.updateSelectedUnit(occupiedUnits.get(unitIndex + 1).get('id'));
    } else {
      const facilityIndex = facilities.findIndex((f) => f.get('id') === facility.id);
      const nextFacility = facilities.get(facilityIndex + 1);
      actions.updateSelectedFacility(nextFacility.get('id'));
      actions.updateSelectedUnit(nextFacility.get('units').filter(u => u.get('system_status') !== 'reserved').first().get('id'));
    }
  };

  handleBackClick = () => {
    const {
      actions,
      facilities,
      facility,
      occupiedUnits,
      unit,
    } = this.props;

    const unitIndex = occupiedUnits.findIndex((u) => u.get('id') === unit.id);
    if (unitIndex > 0) {
      actions.updateSelectedUnit(occupiedUnits.get(unitIndex - 1).get('id'));
    } else {
      const facilityIndex = facilities.findIndex((f) => f.get('id') === facility.id);
      const prevFacility = facilities.get(facilityIndex - 1);
      actions.updateSelectedFacility(prevFacility.get('id'));
      actions.updateSelectedUnit(prevFacility.get('units').filter(u => u.get('system_status') !== 'reserved').last().get('id'));
    }
  };

  renderUnit = () => {
    const {
      classes,
      facilities,
      facility,
      lineItems,
      monthlyCharge,
      moveInDate,
      occupiedUnits,
      unit,
    } = this.props;

    const backDisabled = !facilities || facilities.size === 0
      || !occupiedUnits || occupiedUnits.size === 0
      || (facility && facility.size && facility.id === facilities.first().get('id') && unit.id === occupiedUnits.first().get('id'));

    const nextDisabled = !facilities || facilities.size === 0
      || !occupiedUnits || occupiedUnits.size === 0
      || (facility && facility.size && facility.id === facilities.last().get('id') && unit.id === occupiedUnits.last().get('id'));


    const monthlyCharges = (lineItems ? lineItems.toJS() : []).map((item) => (
      <React.Fragment>
        <Typography color="primary" noWrap>{item.description}</Typography>
        <Typography color="primary" type="body2" align="right">{currency(item.chg)}</Typography>
      </React.Fragment>
    ));

    return facility && unit && (
      <Paper className={classes.gutterBottom}>
        <Grid container className={classes.unitBlock}>
          <Grid item xs={2}>
            <IconButton className={classes.navigationButton} color="secondary" disabled={backDisabled} onClick={this.handleBackClick} title="Previous">
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid className={classes.header} item xs={8}>
            <Grid alignItems="baseline" container justify="center" spacing={16}>
              <Grid item>
                <Typography className={classes.facilityTitle} type="title">{facility.site_code || facility.store_number}:</Typography>
              </Grid>
              <Grid item>
                <Typography type="subheading">{facility.full_address}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton className={`${classes.navigationButton} ${classes.floatRight}`} color="secondary" disabled={nextDisabled} onClick={this.handleNextClick} title="Next">
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
          <Grid item className={classes.col1}>
            <UnitIcon key={`${unit.width}-${unit.length}`} width={unit.width} length={unit.length} />
            <Typography type="subheading" align="center">Unit {unit.unit_number}</Typography>
          </Grid>
          <Grid item className={classes.col2}>
            <Typography align="center" type="title">{`${unit.width}' x ${unit.length}'`}</Typography>
            <Typography align="center" color="textSecondary">{unit.unit_type_name || unit.unit_type}</Typography>
            <Typography align="center" color="textSecondary">{unit.width * unit.length} sq. ft.</Typography>
          </Grid>
          <Grid item className={classes.col3}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              disableTriggerTouch
              title={monthlyCharges}
            >
              <Typography className={classes.helpText} type="headline" align="center" color="secondary">{currency(monthlyCharge)}</Typography>
            </Tooltip>
            <Typography color="textSecondary" align="center">per month</Typography>
          </Grid>
          <Grid item className={classes.col4}>
            <div>
              <Hidden smDown>
                <Typography type="headline" align="center"><b>Move-In Date</b></Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography type="title" align="center"><b>Move-In Date</b></Typography>
              </Hidden>
              <Typography color="secondary" align="center">{moment.utc(moveInDate).format('MMMM D, YYYY')}</Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  render() {
    const {
      classes,
      location,
      loading,
      loaded,
      unitsLoaded,
      match,
    } = this.props;

    if (!unitsLoaded) {
      return (
        <Loading />
      );
    }

    const loadingDom = loading ? <Loading /> :
    <Typography type="headline" color="textSecondary" align="center"> No billing data </Typography>

    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={24}
      >
        <Grid item>
          <UnitSelector />
          <Hidden smDown>
            <MenuList classes={{ root: classes.flex }}>
              {menus.map((option) => (
                <MenuItem
                  key={option.text}
                  selected={option.url === location.pathname}
                  onClick={event => this.handleMenuItemClick(event, option.url)}
                >
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText inset primary={option.text} />
                </MenuItem>
              ))}
            </MenuList>
          </Hidden>
        </Grid>
        <Grid item md={6} xs={12}>
          {loaded ?
            <Paper className="app-paper">
              { this.renderUnit() }
              <Route exact path="/billing" component={CurrentBill} />
              <Route exact path="/billing/payments" component={MakePayment} />
              <Route path="/billing/paymentMethods" component={PaymentMethods} />
              <Route path="/billing/billingHistory" component={BillingHistory} />
              <Route
                exact
                path={`${match.url}/payments/:itemId`}
                render={(routeProps) => (
                  <PaymentForm {...routeProps} onCancel={this.handleCancelPayment} />
                )}
              />
            </Paper>
            :
            loadingDom
          }
        </Grid>
      </Grid>
    );
  }
}

Billing.defaultProps = {
  billingData: undefined,
  facilities: null,
  facility: null,
  lineItems: null,
  loading: false,
  loaded: false,
  monthlyCharge: null,
  moveInDate: null,
  unit: null,
  unitsLoaded: false,
};

Billing.propTypes = {
  actions: PropTypes.shape({
    fetchPaymentAuthorizationMessage: PropTypes.func,
    updateSelectedFacility: PropTypes.func,
    updateSelectedUnit: PropTypes.func,
  }).isRequired,
  billingData: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  facilities: PropTypes.object,
  facility: PropTypes.object,
  lineItems: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  monthlyCharge: PropTypes.string,
  moveInDate: PropTypes.string,
  unit: PropTypes.object,
  unitsLoaded: PropTypes.bool,
  ...routePropTypes,
};

const mapStateToProps = (state) => {
  const invoices = getInvoices(state);
  const moveInDate = invoices && invoices.size > 0 ? invoices.last().get('due_at') : null;
  const selectedFacility = getSelectedFacility(state);
  return {
    billingData: getBillingData(state),
    facilities: getOccupiedFacilities(state),
    facility: result(selectedFacility, 'toJS', null),
    lineItems: getLineItems(state),
    loaded: getLoaded(state),
    loading: getLoading(state),
    monthlyCharge: getExpectedMonthlyCharge(state),
    moveInDate,
    occupiedUnits: selectedFacility && getFacilityOccupiedUnits(state, selectedFacility.get('id')),
    unit: result(getSelectedUnit(state), 'toJS', null),
    unitsLoaded: getUnitsLoaded(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchPaymentAuthorizationMessage: fetchPaymentAuthorizationMessageThunk,
    updateSelectedFacility,
    updateSelectedUnit,
  }, dispatch),
});

const billingContainer = connect(mapStateToProps, mapDispatchToProps)(Billing);
export default withRouter(withStyles(styles)(billingContainer));
