import React from 'react';
import { Field, FormSection } from 'redux-form';
import {
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
} from 'material-ui';
import {
  TextField,
} from 'redux-form-material-ui'

import SelectField from '../../../../components/Form/selectField'

const Bank = () => (
  <FormSection name="bankData">
    <Grid container spacing={0}>
      <Grid item md={12} xs={12}>
        <Field
          fullWidth
          name="nameOnAccount"
          component={TextField}
          label="Account Holder"
          margin="normal"
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Field
          fullWidth
          name="bankName"
          component={TextField}
          label="Bank Name"
          margin="normal"
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth margin="normal" key="accountType">
          <InputLabel htmlFor="accountType">Type</InputLabel>
          <Field
            name="accountType"
            component={SelectField}
          >
            <MenuItem value="checking">Checking</MenuItem>
            <MenuItem value="savings">Savings</MenuItem>
            <MenuItem value="businessChecking">Business Checking</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <Field
          fullWidth
          name="routingNumber"
          component={TextField}
          label="Routing Number"
          margin="normal"
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Field
          fullWidth
          name="accountNumber"
          component={TextField}
          label="Account Number"
          margin="normal"
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  </FormSection>
);

export default Bank
