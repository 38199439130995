import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
} from 'material-ui';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'material-ui/Table';
// import _ from 'lodash';
import moment from 'moment';

import File from '../../../svgs/file';
import { getInvoices } from '../selectors';

const styles = () => ({
  root: {
    borderBottom: 0,
  },
});

class BillingHistory extends React.Component {
  componentDidMount() {
    document.title = this.props.title;
  }

  renderInvoiceRow = (n, idx) => {
    const { classes } = this.props;
    return (
      <TableRow key={`history-${idx}`}>
        <TableCell classes={{ root: classes.root }}>
          {n.due_at && moment.utc(n.due_at).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell classes={{ root: classes.root }}>
          <a href={n.file_url} rel="noreferrer" target="_blank">
            <File />
          </a>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes, invoices } = this.props;

    return (
      <div>
        { invoices.length === 0 ?
          <Typography align="center">No invoices.</Typography>
          :
          (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Due Date</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((n, idx) => this.renderInvoiceRow(n, idx))}
              </TableBody>
            </Table>
          )
        }
      </div>
    );
  }
}

BillingHistory.defaultProps = {
  invoices: [],
  title: 'Billing History',
}

BillingHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({
    due_at: PropTypes.string,
    file_url: PropTypes.string,
  })),
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  invoices: getInvoices(state).toJS(),
});

export default withStyles(styles)(connect(mapStateToProps)(BillingHistory));
