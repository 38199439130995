import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import { result } from 'lodash';

import GeneralForm from '../../../components/Form';
import {
  futureDate,
  required,
} from '../../../utils/validations';
import { dateMask } from '../../../constants'
import AutoInsuranceField from '../../../components/Form/autoInsuranceField';
import { getAccountId } from '../../Auth/selectors';
import { getCustomerInsurance } from '../selectors';
import { createCustomerInsuranceFormThunk, updateCustomerInsuranceFormThunk } from '../reducer';

const fields = [{
  custom: (
    <Field
      fullWidth
      required
      component={AutoInsuranceField}
      InputLabelProps={{ className: 'required', shrink: true }}
      name="carrier_name"
      validate={required}
    />
  ),
}, {
  fullWidth: true,
  required: true,
  label: 'Policy Number',
  name: 'policy_number',
  validate: required,
}, {
  fullWidth: true,
  required: true,
  label: 'Expiration Date',
  name: 'end_date',
  mask: dateMask,
  validate: [required, futureDate],
}];

const mapStateToProps = (state, ownProps) => {
  const insuranceValues = ownProps.isCreate
    ? { account_id: getAccountId(state), carrier_name: '' }
    : result(getCustomerInsurance(state, ownProps.match.params.itemId), 'toJS', { account_id: getAccountId(state), carrier_name: '' });

  if (insuranceValues.end_date) {
    insuranceValues.end_date = moment(insuranceValues.end_date).format('MM/DD/YYYY');
  }

  if (!ownProps.isCreate) {
    insuranceValues.redirect = true;
  }

  return {
    fields,
    initialValues: insuranceValues,
    shrinkLabels: true,
  };
};

// Only re-create bound methods if "ownProps.next" changes
const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    create: createCustomerInsuranceFormThunk,
    update: updateCustomerInsuranceFormThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
})

// Decorate with redux-form
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ enableReinitialize: true, form: 'vehicleInsuranceForm' })(GeneralForm));
