import React from 'react';

const SvgComponent = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={34}
    height={48}
    viewBox="0 0 34 48"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M6 0h16l12 12v30a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#a" />
      <path
        stroke="#09F"
        strokeWidth={2}
        d="M33 12.414L21.586 1H6a5 5 0 0 0-5 5v36a5 5 0 0 0 5 5h22a5 5 0 0 0 5-5V12.414z"
      />
      <g fill="#80CCFF" transform="translate(5 5)">
        <rect width={8} height={8} rx={2} />
        <path d="M0 10h24v2H0zm0 4h24v2H0zm0 4h24v2H0zm0 4h24v2H0zm0 4h24v2H0zm0 4h14v2H0z" />
      </g>
      <path fill="#09F" d="M21 0h1l12 12v1h-6.5c-3.59 0-6.5-2.917-6.5-6.5V0z" />
    </g>
  </svg>
);

export default SvgComponent;
