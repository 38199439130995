import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from 'material-ui';
import { KeyboardArrowLeft, MyLocation } from 'material-ui-icons';

import './styles.css';

const MapSearch = ({
  className,
  defaultSearch,
  onCancel,
  onGeocode,
  onLocate,
}) => {
  const [searchValue, setSearchValue] = useState(defaultSearch);

  const geocodeAddress = useCallback(() => {
    onGeocode(searchValue);
  }, [onGeocode, searchValue]);

  const onInputChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const onKeyPress = useCallback((e) => {
    if (e.keyCode === 13) {
      onGeocode(e.target.value);
    }
  }, [onGeocode]);

  return (
    <Grid className={className}>
      <Grid className="search-container">
        {/* <Typography className="search-title" type="display1">Location Search</Typography> */}
        <Grid className="search-box">
          <input
            defaultValue={defaultSearch}
            type="text"
            className="search-term"
            placeholder="Search by Address, City, State, or Zip"
            onBlur={onInputChange}
            onKeyUp={onKeyPress}
          />
          <IconButton
            className="inset-button"
            color="secondary"
            onClick={onLocate}
            title="Find My Location"
          >
            <MyLocation />
          </IconButton>
          <button
            type="submit"
            className="search-button"
            onClick={geocodeAddress}
          >
            Search
          </button>
        </Grid>
      </Grid>
      <button className="cancel-button" onClick={onCancel}>
        <KeyboardArrowLeft />
      </button>
    </Grid>
  );
};

MapSearch.defaultProps = {
  className: 'search-header',
  defaultSearch: '',
  onCancel: undefined,
};

MapSearch.propTypes = {
  className: PropTypes.string,
  defaultSearch: PropTypes.string,
  onCancel: PropTypes.func,
  onGeocode: PropTypes.func.isRequired,
  onLocate: PropTypes.func.isRequired,
};

export default MapSearch;
