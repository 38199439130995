import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio,
  withStyles,
} from 'material-ui';
import { RadioGroup } from 'redux-form-material-ui';

import Card from './Card';
import Bank from './Bank';

const styles = () => ({
  hidden: {
    display: 'none',
  },
  profileType: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
});

class NewPaymentForm extends Component {
  state = {
    profile_type: this.props.paymentMethods.length > 0
      ? this.props.paymentMethods[0].value : undefined,
  };
  render() {
    const { classes, paymentMethods } = this.props;
    const selectedMethod = paymentMethods
      .find((profile) => profile.value === this.state.profile_type);

    return (
      <div style={{ flex: 1 }}>
        <Field
          name="profile_type"
          component={RadioGroup}
          className={`${classes.profileType}${paymentMethods.length <= 1 ? ` ${classes.hidden}` : ''}`}
          onChange={(event, newValue) => { this.setState({ profile_type: newValue }) }}
        >
          {paymentMethods.map((type) => (
            <FormControlLabel
              control={<Radio />}
              key={type.value}
              label={type.label}
              value={type.value}
            />
          ))}
        </Field>
        {selectedMethod && selectedMethod.element}
      </div>
    );
  }
}
NewPaymentForm.defaultProps = {
  paymentMethods: [{
    element: <Card />,
    label: 'Card',
    value: 'card',
  }, {
    element: <Bank />,
    label: 'Bank Account',
    value: 'ach',
  }],
};
NewPaymentForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    element: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default withStyles(styles)(NewPaymentForm);
