/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import Title from '../../components/Title';

const styles = (/* theme */) => ({
  bodyContent: {
    fontSize: '19px',
    padding: '0.5em 0',
  },
  pageContainer: {
    gap: '1em',
  },
});

const ContactUs = ({
  classes,
  email,
  phone,
  title,
}) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid className={classes.pageContainer} container justify="center">
      <Title>
        <Typography type="display4">RED DOT STORAGE&apos;S</Typography>
        <Typography type="display2">PRIVACY POLICY</Typography>
      </Title>
      <Grid item xs={12} sm={11} md={10} lg={8}>
        <Typography className={classes.bodyContent} type="body1">This website privacy policy (&quot;Privacy Policy&quot;) describes the practices of Red Dot Storage regarding Personally Information collected via its website at reddotstorage.com (the “Website”). This Privacy Policy applies only to Personal Information that we gather or collect via the Website. It does not apply to any information collected offline or otherwise outside of the Website.</Typography>
        <Typography className={classes.bodyContent} type="body1">1. Information We May Collect. When you visit our Website we may collect two types of information about you: (1) information that can be used to identify you (&quot;Personal Information&quot;), and (2) information related to your activities on the Website that we automatically collect as you interact with the Website (&quot;Website Usage Information&quot;).</Typography>
        <Typography className={classes.bodyContent} type="body1">(a) Personal Information You Voluntarily Provide to Us. Generally, you can visit our Website without telling us who you are and without giving us any Personal Information. The only way that we collect Personal Information at the Website is if you voluntarily enter the information in a form we provide on the Website, or if you use one of the features on the Website for sending us a message. In such case, we will collect whatever Personal Information you voluntarily provide, such as your first and last name, telephone number, postal and email addresses and billing information (such as credit card number, cardholder name, and expiration date). For example, if you fill out an online submission form, we will collect whatever information you enter into the form and whatever files you upload. Likewise, if you send us a message via the website (such as by entering it on a “Contact Us” page), you will be sending us your name, e-mail address, and any other Personal Information you choose to include in that communication. You are under no obligation to provide such Personal Information, but if you chose not to you may be unable to use certain features of, or communicate with us via, the Website.</Typography>
        <Typography className={classes.bodyContent} type="body1">(b) Website Usage Information We Automatically Collect. When you visit the Website, we automatically collect certain Website Usage Information related to your activities on the Website. Website Usage Information may include your IP address, browser type and operating system, the domain from which you came, the page you are viewing, the time, and what you clicked. To collect Website Usage Information, we may utilize a variety of technologies, such as cookies (a small data file placed on your computer when you visit the Website), web beacons (small graphic images or other web programming code that may be included in our web pages and e-mail messages), embedded scripts (code that is designed to collect information about your interactions with the Website, such as which links you click on), or similar technologies.</Typography>
        <Typography className={classes.bodyContent} type="body1">Website Usage Information is typically anonymous information, since it is not normally associated with you or your identity. However, if it is combined with your Personal Information in a manner that it is directly associated with your identity it will be treated as Personal Information under this Privacy Policy.</Typography>
        <Typography className={classes.bodyContent} type="body1">(c) Children. The Website does not offer products or services to children, and we do not knowingly collect any Personal Information from children younger than the age of thirteen (13). We will delete any Personal Information collected that we later determine to be from a person younger than the age of thirteen (13).</Typography>
        <Typography className={classes.bodyContent} type="body1">2. Use of Personal Information. We may retain and use Personal Information collected via the Website for various purposes, primarily relating to communicating with you and processing transactions you may enter into. These include: (1) to respond to your inquiries; (2) to provide you with the products and services you request; (3) to complete self storage reservation(s) you make at our facilities and to provide you with self storage booking confirmation and updates; (4) to manage your account, including processing bills and providing rent notifications or property updates; (5) to process your payment transactions; and (6) to respond to your questions and comments. We may also retain and use Personal Information collected via the Website to communicate with you in general; to improve the Website or our services or to customize your experience on the Website; to notify you about special offers and products or services that may be of interest to you; to solicit information from you, including through surveys; to resolve disputes, collect fees, or troubleshoot problems; to prevent potentially prohibited or illegal activities; to enforce our Terms of Use; for our internal business purposes; and as otherwise described to you at the point of collection. The Website Usage Information we collect may be used for a number of purposes, such as system administration, to help us understand the demographics of the visitors to the Website, to enhance the performance and usefulness of the Website, or to audit use of the Website.</Typography>
        <Typography className={classes.bodyContent} type="body1">3. Sharing of Personal Information With Third Parties. We may share Personal Information with the following entities or in the following circumstances:</Typography>
        <Typography className={classes.bodyContent} type="body1">Third Parties Providing Services on Our Behalf. We may share Personal Information with third parties who perform services on our behalf, solely for the purpose of providing such services. This includes, without limitation, third parties that may host the Website or operate certain functions or features of the Website, archive our data, send communications, analyze data, or create, or provide other services or functions on our behalf, including credit card processing, business analytics, customer service, marketing, distribution of surveys or contests, and fraud prevention.</Typography>
        <Typography className={classes.bodyContent} type="body1">Business Transfers and Corporate Changes. We reserve the right to disclose and transfer Personal Information in connection with a corporate divestiture, merger, consolidation, or restructuring, the sale of substantially all of our ownership interests and/or assets, or other corporate change, including, without limitation, during the course of any due diligence process or in the unlikely event of bankruptcy.</Typography>
        <Typography className={classes.bodyContent} type="body1">Legal Requirements and Law Enforcement. We may also disclose and transfer Personal Information to third parties: (i) in the event we are required to respond to a court order, subpoena, discovery request, or other legal process, or if in our good faith opinion such disclosure is required by law; (ii) at the request of governmental authorities conducting an audit or investigation; (iii) to verify or enforce compliance with other agreements or policies governing the Website, or applicable laws, rules, and regulations; or (iv) whenever we believe disclosure is necessary to limit our legal liability or to protect or enforce the rights, interests, or safety of our company or the Website, its users or other third parties; and in connection with our Terms of Service and other agreements. We also reserve the right to investigate, prevent, or take action regarding illegal or suspected illegal activities and to report to law enforcement agencies any activities that we, in good faith, believe to be unlawful.</Typography>
        <Typography className={classes.bodyContent} type="body1">Sharing of Non-Personal Information. We may also share non-personally identifiable information, such as Website Usage Information or other aggregated user statistics, as well as anonymous information derived from Personal Information, such as de-identified user information, with third parties for any purpose.</Typography>
        <Typography className={classes.bodyContent} type="body1">4. Security. We incorporate safeguards to help protect and secure Personal Information we collect via the Website. This includes the use of the SSL encryption protocol to protect your account and credit card data during transmission to us. However, no data transmission over the Internet, wireless transmission, or electronic storage of information can be guaranteed to be completely secure. Please consider this prior to submitting Personal Information to us via the Site.</Typography>
        <Typography className={classes.bodyContent} type="body1">5. Consent to Transfer Information to the United States. The Website is operated in the United States. By providing us with any information via the Website, you consent to the transfer and processing of your information in the United States.</Typography>
        <Typography className={classes.bodyContent} type="body1">6. Changes to the Privacy Policy. We reserve the right to change this Privacy Policy at any time. Any changes will be effective immediately upon the posting of the revised Privacy Policy. However, we will not use Personal Information collected via this Website in a manner materially different than what was stated at the time it was collected unless we receive your consent.</Typography>
        <Typography className={classes.bodyContent} type="body1">7. Contact Information. If you have any questions about this Agreement or this Policy you may contact us via e-mail at <a className="link" href={`mailto:${email}`} rel="noreferrer" target="_blank">{email}</a>, or via telephone at <a className="link" href={`tel:${phone.replace(/\D/g, '')}`}>{phone}</a>.</Typography>
      </Grid>
    </Grid>
  );
};

ContactUs.defaultProps = {
  email: 'info@reddotstorage.com',
  phone: '+1 (855) 733-3681',
  title: 'Privacy Policy',
};

ContactUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(ContactUs);
