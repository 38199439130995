import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteField from './autocompleteField';

const AUTO_INSURANCE_CARRIERS = [
  { label: 'State Farm' },
  { label: 'Allstate' },
  { label: 'Progressive' },
  { label: 'Geico' },
  { label: 'USAA' },
  { label: 'Liberty Mutual Group' },
  { label: 'Farmers Insurance Group' },
  { label: 'Travelers' },
  { label: 'American Family Insurance' },
  { label: 'Nationwide Mutual Group' },
  { label: 'Erie Insurance' },
  { label: 'Auto Club Exchange' },
  { label: 'Chubb' },
  { label: 'CSAA Insurance Exchange' },
  { label: 'Kemper' },
  { label: 'MetLife' },
  { label: 'Mercury General' },
  { label: 'Hartford Financial Services' },
  { label: 'Auto Club Insurance Association' },
  { label: 'Amica Mutual Insurance' },
  { label: 'COUNTRY Financial' },
  { label: 'Hanover Insurance Group' },
  { label: 'Shelter Insurance' },
  { label: 'Universal Insurance Holdings' },
  { label: 'Southern Farm Bureau Casualty' },
  { label: 'American International Group (AIG)' },
  { label: 'NJM Insurance Group' },
  { label: 'Cincinnati Financial' },
  { label: 'Tenessee Farmers Mutual' },
  { label: 'Alfa Mutual Group' },
  { label: 'Texas Farm Bureau' },
  { label: 'Tokio Marine' },
  { label: 'State Auto Insurance' },
  { label: 'Farm Bureau Financial Services' },
  { label: 'North Carolina Farm Bureau Insurance' },
  { label: 'Kentucky Farm Bureau' },
  { label: 'UPC Insurance' },
  { label: 'Plymouth Rock of New Jersey' },
  { label: 'Grange Insurance' },
  { label: 'Heritage Insurance' },
  { label: 'American National Insurance' },
  { label: 'Markel' },
  { label: 'Assurant' },
  { label: 'Westfield Insurance' },
  { label: 'Citizens Property Insurance' },
  { label: 'Munich Re' },
  { label: 'Michigan Farm Bureau' },
  { label: 'Plymouth Rock Assurance' },
  { label: 'Safety Insurance' },
  { label: 'FEDNAT' },
];

const AutoInsuranceField = ({ label, ...autocompleteProps }) => (
  <AutocompleteField label={label} suggestions={AUTO_INSURANCE_CARRIERS} {...autocompleteProps} />
);

AutoInsuranceField.defaultProps = {
  label: 'Carrier',
}

AutoInsuranceField.propTypes = {
  label: PropTypes.string,
};

export default AutoInsuranceField;
