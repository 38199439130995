import { call } from 'redux-saga/effects';
import URLSearchParams from 'url-search-params';
import fetchData from './apiRequest';

// Side effects Services
export function getAuthHeader() {
  let authHeader = localStorage.getItem('authHeader');
  try {
    authHeader = JSON.parse(authHeader);
  } catch (e) {
    authHeader = {};
  }

  return authHeader;
}

export function extractAuthHeader(headers) {
  return ['access-token', 'client', 'expiry', 'uid', 'token-type'].reduce((map, obj) => ({
    ...map,
    [obj]: headers.get(obj),
  }), {});
}

export function setAuthHeader(headers) {
  // only when it is perfect scheme
  if (headers['access-token']) {
    localStorage.setItem('authHeader', JSON.stringify(headers));
    return true;
  }
  return false;
}

export function removeAuthHeader() {
  localStorage.removeItem('authHeader');
}

export function setAuthTokens(payload) {
  if (payload.accessToken) {
    localStorage.setItem('authTokens', JSON.stringify(payload));
    return true;
  }
  return false;
}

export function getAccessToken() {
  let authTokens = localStorage.getItem('authTokens');
  try {
    authTokens = JSON.parse(authTokens) || {};
  } catch (e) {
    authTokens = {};
  }

  return authTokens.accessToken;
}

export function getRefreshToken() {
  let authTokens = localStorage.getItem('authTokens');
  try {
    authTokens = JSON.parse(authTokens) || {};
  } catch (e) {
    authTokens = {};
  }

  return authTokens.refreshToken;
}

export function getBearerAuthorization() {
  const accessToken = getAccessToken();
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
}

export function removeAuthTokens() {
  localStorage.removeItem('authTokens');
}

export function* authenticate(formData) {
  // Login to legacy Redline
  return yield call(
    fetchData,
    '/api/customer/v1/auth/sign_in',
    {
      method: 'POST',
      body: formData,
    },
  );
}

export function* login(formData) {
  // Login to Redline-API (new back-end)
  return yield call(
    fetchData,
    '/auth/login',
    {
      method: 'POST',
      body: {
        username: formData.email,
        password: formData.password,
      },
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function sendResetEmail(formData) {
  const apiUrl = formData.isFirst ? '/api/customer/v1/verify_email' : '/api/customer/v1/auth/password'
  return fetchData(
    apiUrl,
    {
      method: 'POST',
      body: formData,
    },
  );
}

export function resetPassword(formData) {
  const authHeader = {};
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('reset_password')) {
    authHeader['access-token'] = urlParams.get('token');
    authHeader.client = urlParams.get('client_id');
    authHeader.uid = urlParams.get('uid');
    authHeader.expiry = urlParams.get('expiry');
  }
  setAuthHeader(authHeader);

  return fetchData(
    '/api/customer/v1/auth/password',
    {
      method: 'PUT',
      body: formData,
    },
  );
}

export function sendAccountConfirmation(data) {
  return fetchData(
    '/accounts/user/confirm',
    {
      method: 'PATCH',
      body: data,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function confirmAccount(payload) {
  const { user_id: id, ...data } = payload;
  return fetchData(
    `/users/${id}/activate`,
    {
      method: 'PATCH',
      body: data,
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

/**
Purpose: refresh token
Notes:   invoke this method on app load(App/index.js) and token expiration
*/
export function* refreshToken() {
  return yield call(
    fetchData,
    '/api/customer/v1/auth/validate_token',
    {
      method: 'GET',
    },
  );
}

export function* refreshBearerToken() {
  const token = getRefreshToken();
  if (!token) {
    return { status: 400, error: { message: 'RefreshToken not found.' } };
  }

  return yield call(
    fetchData,
    '/auth/refresh-token',
    {
      method: 'POST',
      body: { refreshToken: token },
    },
    process.env.REACT_APP_RR_API_HOST,
  );
}

export function* fetchUser(id) {
  return yield call(
    fetchData,
    `/users/${id}`,
    { method: 'GET' },
    process.env.REACT_APP_RR_API_HOST,
  );
}
