import { createMuiTheme } from 'material-ui/styles';

import Colors from './colors';

/* These customization are based on great MUI class-based CSS design rule */
/* Consider /node_modules/material-ui/Input/InputLabel.d.ts as an example */

export default createMuiTheme({
  palette: {
    primary: {
      main: Colors.white,
    },
    secondary: {
      main: Colors.red,
    },
    text: {
      secondary: Colors.textSecondary,
    },
    background: {
      light: Colors.lightBackground,
      dark: Colors.darkBackground,
    },
    border: {
      dark: Colors.darkBorder,
    },
  },
  overrides: {
    MuiButton: {
      raised: {
        'font-weight': 600,
      },
      raisedSecondary: {
        'font-weight': 600,
      },
      root: {
        'border-radius': '30px',
        'text-transform': 'initial',
      },
    },
    MuiCheckbox: {
      checked: {
        color: Colors.blue,
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          'background-color': 'none',
        },
        '&:first-child': {
          'border-top-left-radius': '30px',
          'border-top-right-radius': '30px',
        },
        '&:last-child': {
          'border-bottom-left-radius': '30px',
          'border-bottom-right-radius': '30px',
        },
        'border-radius': '30px',
        'box-shadow': 'none',
        'margin-bottom': '5px',
      },
      expanded: {
        '&:before': {
          'background-color': 'none',
        },
        margin: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        'flex-direction': 'column',
        gap: '1em',
      },
    },
    MuiExpansionPanelSummary: {
      contentExpanded: {
        margin: '12px 0',
      },
      expanded: {
        'min-height': '48px',
      },
      expandIcon: {
        color: Colors.white,
      },
      root: {
        'background-color': Colors.red,
        'border-radius': '30px',
      },
    },
    MuiFormGroup: {
      root: {
        'padding-top': '1em',
      },
    },
    MuiInput: {
      root: {
        'align-items': 'center',
        color: Colors.red,
        border: '1px solid lightgrey',
        'border-radius': '30px',
      },
      input: {
        minHeight: '1.1875em',
        padding: '6px 6px 7px',
        'border-radius': '30px',
      },
      inputMultiline: {
        padding: '0 1em',
      },
      underline: {
        '&:before': {
          height: '0 !important',
        },
      },
      inkbar: {
        '&:after': {
          height: '0 !important',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        marginTop: '0 !important',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          color: Colors.red,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: Colors.red,
        'text-transform': 'uppercase',
        'padding-left': '6px',
      },
      shrink: {
        color: Colors.textPrimary,
        'padding-left': 0,
        whiteSpace: 'nowrap',
      },
    },
    MuiRadio: {
      checked: {
        color: Colors.blue,
      },
    },
    MuiSelect: {
      select: {
        'padding-right': '26px',
        '&:focus': {
          'border-radius': '30px',
        },
      },
    },
    MuiSnackbar: {
      anchorBottomCenter: {
        bottom: '0.5em',
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-between',
      },
    },
    MuiTab: {
      rootPrimary: {
        color: Colors.black,
        '&:hover': {
          backgroundColor: Colors.lightBackground,
        },
      },
      rootInherit: {
        borderRadius: '10px 10px 0 0',
        '&:hover': {
          backgroundColor: Colors.darkBackground,
          color: Colors.white,
        },
      },
      label: {
        'font-size': '1rem',
      },
      rootPrimarySelected: {
        color: Colors.black,
      },
      rootInheritSelected: {
        background: Colors.white,
        border: `1px solid ${Colors.darkBorder}`,
        borderBottomWidth: 0,

      },
      rootLabelIcon: {
        height: 60,
      },
    },
    MuiTabIndicator: {
      colorPrimary: {
        backgroundColor: Colors.black,
      },
      colorSecondary: {
        display: 'none',
      },
    },
    MuiTypography: {
      body2: {
        fontWeight: 'bold',
      },
      subheading: {
        whiteSpace: 'nowrap',
      },
      display1: {
        color: Colors.black,
        fontWeight: 'bold',
      },
      display2: {
        color: '#373a3c',
        fontSize: '40px',
        fontWeight: 900,
      },
      display4: {
        color: '#373a3c',
        fontSize: '20px',
        fontWeight: 900,
      },
    },
    MuiFormControl: {
      fullWidth: {
        width: '100%',
        flexBasis: '100%',
      },
      root: {
        paddingRight: 8,
        width: '50%',
        flexBasis: '50%',
      },
    },
    MuiTableCell: {
      paddingDefault: {
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 300,
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 48,
      },
      gutters: {
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
    MuiListItemText: {
      root: {
        '&:first-child': {
          padding: 0,
        },
      },
      textDense: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});
