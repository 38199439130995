import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  Divider,
  Button,
} from 'material-ui';
import { RadioGroup } from 'redux-form-material-ui';
import {
  AccountBalance,
  Info,
  Add,
} from 'material-ui-icons';
import { withRouter } from 'react-router-dom';
import { routePropTypes } from '../../../utils/routes';

import CreditCardIcon from '../../../svgs/credit-card';
import {
  getPayments,
  getDefaultPaymentId,
} from '../selectors';

const styles = () => ({
  marginFive: {
    marginLeft: 50,
  },
  radioLabel: {
    flexGrow: 1,
  },
  floatRight: {
    marginLeft: 'auto',
  },
  paymentType: {
    textAlign: 'center',
    width: '3rem',
  },
  svg: {
    height: '2rem',
    width: '2rem',
    fill: 'rgba(0, 0, 0, 0.54)',
  },
})

class PaymentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPayment: props.defaultPaymentId,
    };
    if (!this.state.selectedPayment && props.payments.length > 0) {
      this.state.selectedPayment = props.payments[props.payments.length - 1].id.toString();
    }
  }

  renderPaymentItem(p) {
    const {
      defaultPaymentId,
      classes,
    } = this.props

    return (
      <FormControlLabel
        value={p.id.toString()}
        control={<Radio />}
        key={p.id}
        classes={{ label: classes.radioLabel }}
        label={
          <div className="flex-center">
            <div className={[classes.paymentType, classes.marginFive].join(' ')}>
              {p.profile_type === 'ach' ?
                <AccountBalance className={classes.svg} />
                :
                <CreditCardIcon className={classes.svg} />
              }
            </div>
            <span className={classes.marginFive}>{p.line}</span>
            {p.id.toString() === defaultPaymentId
              && <Typography className={classes.floatRight} type="body2">Default</Typography>}
          </div>
        }
      />
    );
  }

  render() {
    const {
      defaultPaymentId,
      match,
      payments,
    } = this.props

    return (
      <Grid>
        <Typography type="subheading" color="textSecondary">Select Payment Method</Typography>
        <Divider />
        <div style={{ padding: '12px 0px' }}>
          {
            payments.length ?
              <Field
                name="payment_id"
                component={RadioGroup}
                onChange={(event, newValue) => this.setState({ selectedPayment: newValue })}
              >
                { payments.map(p => this.renderPaymentItem(p)) }
              </Field>
            :
              <Typography color="textSecondary" align="center">No Payment Method available.</Typography>
          }
          <div className="t-right">
            <Button
              fab
              mini
              onClick={() => this.props.history.push(`${match.url}/create`)}
              color="secondary"
            >
              <Add className="app-white-svg" />
            </Button>
          </div>
        </div>
        <Divider />

        {this.state.selectedPayment !== defaultPaymentId &&
          <div className="flex-center" style={{ margin: '20px 0 20px 0' }}>
            <Info className="app-blue-svg" style={{ marginRight: 10 }} />
            <Typography>{`${(payments.find(s => s.id.toString() === this.state.selectedPayment) || {}).line} will be set as a default payment method.`}</Typography>
          </div>
        }
      </Grid>
    )
  }
}

PaymentList.defaultProps = {
  defaultPaymentId: undefined,
  payments: [],
}

PaymentList.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultPaymentId: PropTypes.string,
  payments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_type: PropTypes.string,
    line: PropTypes.node,
  })),
  ...routePropTypes,
}

const mapStateToProps = (state) => ({
  defaultPaymentId: getDefaultPaymentId(state),
  payments: getPayments(state).toJS(),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(PaymentList)));
