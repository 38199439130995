import { fork, all } from 'redux-saga/effects';

import authSagas from './containers/Auth/sagas';
import unitsSaga from './containers/MyUnits/sagas';
import billingSaga from './containers/Billing/sagas';
import searchMapSaga from './containers/SearchMap/sagas';
import facilitySaga from './containers/Facility/sagas';
import reservationSaga from './containers/Reservation/sagas';
import leaseSaga from './containers/LeaseAgreement/sagas';

const sagas = [
  authSagas,
  unitsSaga,
  billingSaga,
  searchMapSaga,
  facilitySaga,
  reservationSaga,
  leaseSaga,
  // NOTE: put other app sagas here
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));

  yield all([...globalSagasForks]);
}

export default globalSagas;
