import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyField = props => {
  const {
    inputRef,
    onChange,
    min,
    max,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={2}
      thousandSeparator
      prefix="$"
      isAllowed={(values) => {
        const { floatValue } = values;
        return typeof floatValue !== 'number' || (floatValue >= min && floatValue <= max);
      }}
    />
  );
}
CurrencyField.defaultProps = {
  min: 0,
  max: Number.MAX_VALUE,
};

CurrencyField.propTypes = {
  inputRef: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default CurrencyField;
