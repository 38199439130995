/* eslint-disable no-param-reassign */
import { UsaStates } from 'usa-states';
import { minLength } from './utils/validations';

const { states } = new UsaStates();

const licenseMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const stateLicenseMask = states.reduce((hash, item) => {
  switch (item.abbreviation) {
    case 'AK':
    case 'DE':
      hash[item.abbreviation] = {
        mask: licenseMask,
        validate: [minLength(7)],
      };
      break;
    case 'DC':
    case 'GA':
    case 'TN':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/),
        validate: [minLength(7)],
      };
      break;
    case 'CT':
    case 'LA':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/),
        validate: [minLength(9)],
      };
      break;
    case 'OK':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'FL':
    case 'MD':
    case 'MN':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(13)],
      };
      break;
    case 'AL':
    case 'TX':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/),
        validate: [minLength(7)],
      };
      break;
    case 'AZ':
    case 'HI':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'AR':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/),
        validate: [minLength(4)],
      };
      break;
    case 'CA':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/].concat(licenseMask),
        validate: [minLength(8)],
      };
      break;
    case 'CO':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(5, 'Field must have at least 4 characters')],
      };
      break;
    case 'ID':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /[a-zA-Z0-9]/],
        validate: [minLength(9)],
      };
      break;
    case 'IL':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(12)],
      };
      break;
    case 'IN':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(11, 'Field must have at least 9 characters')],
      };
      break;
    case 'IA':
      hash[item.abbreviation] = {
        mask: [/\d/, /\d/, /\d/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(9)],
      };
      break;
    case 'KS':
      hash[item.abbreviation] = {
        mask: (userInput) => (userInput.length < 6
          ? [/[a-zA-Z0-9]/, /\d/, /[a-zA-z0-9]/, /\d/, /[a-zA-Z0-9]/]
          : [/[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]),
        validate: [minLength(5)],
      };
      break;
    case 'KY':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(11, 'Field must have at least 9 characters')],
      };
      break;
    case 'ME':
      hash[item.abbreviation] = {
        mask: licenseMask.concat([/[a-zA-Z0-9]/]),
        validate: [minLength(7)],
      };
      break;
    case 'MA':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'MI':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(11)],
      };
      break;
    case 'MS':
      hash[item.abbreviation] = {
        mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(11, 'Field must have at least 9 numbers')],
      };
      break;
    case 'MO':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /[0-9rR]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
        validate: [minLength(6)],
      };
      break;
    case 'MT':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'NE':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/].concat(licenseMask),
        validate: [minLength(7)],
      };
      break;
    case 'NV':
      hash[item.abbreviation] = {
        mask: [/[xX0-9]/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'NH':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(10)],
      };
      break;
    case 'NJ':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(17, 'Field must have at least 15 characters')],
      };
      break;
    case 'NM':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/),
        validate: [minLength(8)],
      };
      break;
    case 'NY':
      hash[item.abbreviation] = {
        mask: (userInput) => {
          if (userInput.length > 1 && Number.isNaN(parseInt(userInput.charAt(1), 10))) {
            return [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/];
          }

          return [/[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask, licenseMask);
        },
        validate: [minLength(8)],
      };
      break;
    case 'NC':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/, /\d/, /\d/, /\d/),
        validate: [minLength(12)],
      };
      break;
    case 'ND':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(11, 'Field must have at least 9 characters')],
      };
      break;
    case 'OH':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(5)],
      };
      break;
    case 'OR':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/].concat(licenseMask),
        validate: [minLength(7)],
      };
      break;
    case 'PA':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/),
        validate: [minLength(8)],
      };
      break;
    case 'RI':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]0-9/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(7)],
      };
      break;
    case 'SC':
      hash[item.abbreviation] = {
        mask: licenseMask.concat([/\d/, /\d/, /\d/, /\d/]),
        validate: [minLength(5)],
      };
      break;
    case 'SD':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/, /\d/, /\d/, /\d/),
        validate: [minLength(6)],
      };
      break;
    case 'UT':
      hash[item.abbreviation] = {
        mask: licenseMask.concat(/\d/, /\d/, /\d/),
        validate: [minLength(4)],
      };
      break;
    case 'VT':
      hash[item.abbreviation] = {
        mask: licenseMask.concat([/[a-zA-Z0-9]/]),
        validate: [minLength(8)],
      };
      break;
    case 'VA':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/].concat(licenseMask),
        validate: [minLength(9)],
      };
      break;
    case 'WA':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z0-9*]/, /[a-zA-Z0-9*]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
        validate: [minLength(12)],
      };
      break;
    case 'WV':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(7)],
      };
      break;
    case 'WI':
      hash[item.abbreviation] = {
        mask: [/[a-zA-Z]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
        validate: [minLength(17, 'Field must have at least 14 characters')],
      };
      break;
    case 'WY':
      hash[item.abbreviation] = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        validate: [minLength(10, 'Field must have at least 9 numbers')],
      };
      break;
    default:
      hash[item.abbreviation] = {};
  }

  return hash;
}, {});

const USPhoneMask = [
  '(', /\d/, /\d/, /\d/, ')',
  ' ',
  /\d/, /\d/, /\d/,
  '-',
  /\d/, /\d/, /\d/, /\d/,
];

export const phoneMaskWithoutExtension = [
  '+', '1', ' ',
  ...USPhoneMask,
];
export const phoneMaskWithExtension = [
  ...phoneMaskWithoutExtension,
  ' ',
  'x', /\d/, /\d/, /\d/, /\d/, /\d/,
];
export const phoneMaskWithCountryCode = [
  '+', /\d/, ' ',
  ...USPhoneMask,
];

export function phoneMask(userInput) {
  const numbers = userInput.replace(/^\+1/, '').match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  if (numberLength > 10) {
    return numberLength === 11 && userInput.charAt(0) === '1' ? phoneMaskWithCountryCode : phoneMaskWithExtension;
  }

  return userInput.startsWith('+1') ? phoneMaskWithoutExtension : USPhoneMask;
}

export function phoneFormat(conformedValue) {
  if (!conformedValue || conformedValue.startsWith('+1 ')) return conformedValue;

  return {
    value: `+1 ${conformedValue}`,
    indexesOfPipedChars: [0, 1, 2],
  };
}

export const cardNumberMask = [
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/,
];

export const cardMonthMask = [/\d/, /\d/];
export const cardYearMask = [/\d/, /\d/, /\d/, /\d/];
export const cardCcvMask = [/\d/, /\d/, /\d/, /\d/];

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const zipMaskWithoutExtension = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const zipMaskWithExtension = [
  ...zipMaskWithoutExtension,
  '-',
  /\d/, /\d/, /\d/, /\d/,
];

export function zipMask(userInput) {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  return numberLength <= zipMaskWithoutExtension.length
    ? zipMaskWithoutExtension : zipMaskWithExtension;
}
