import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { Checkbox } from 'redux-form-material-ui';
import {
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
} from 'material-ui';
import { result } from 'lodash';

import GeneralForm from '../../components/Form';
import SelectField from '../../components/Form/selectField';
import { createContactThunk, updateContactThunk } from './reducer';
import { getContact, getContacts } from '../Auth/selectors';
import { minLength, required } from '../../utils/validations';
import { phoneMask, phoneFormat } from '../../constants';

const fields = [{
  name: 'phone',
  label: 'Phone',
  required: true,
  mask: phoneMask,
  pipe: phoneFormat,
  validate: [required, minLength(11, 'Phone Number must contain at least 10 digits', /\D/g)],
}, {
  custom: (
    <FormControl margin="normal" key="label">
      <InputLabel className="required" htmlFor="label" required shrink>Type</InputLabel>
      <Field
        name="label"
        component={SelectField}
        validate={required}
      >
        <MenuItem value="home">Home</MenuItem>
        <MenuItem value="work">Work</MenuItem>
        <MenuItem value="mobile">Mobile</MenuItem>
      </Field>
    </FormControl>
  ),
  name: 'label',
}, {
  custom: (
    <FormControlLabel
      control={
        <Field
          name="default"
          component={Checkbox}
        />
      }
      label="Primary"
      key="default"
    />
  ),
  name: 'default',
}];

const protectedFields = fields.map((field, index) => {
  if (index < fields.length - 1) return field;
  return {
    custom: (
      <FormControlLabel
        control={
          <Field
            name="default"
            component={Checkbox}
          />
        }
        disabled
        label="Primary"
        key="default"
      />
    ),
    name: 'default',
  }
});

const mapStateToProps = (state, ownProps) => {
  /* Default to primary if no current contact exists */
  let initValues;
  const contacts = getContacts(state);
  let contactId = parseInt(ownProps.match.params.itemId, 10);
  if (Number.isNaN(contactId)) contactId = ownProps.match.params.itemId;
  if (!contacts || contacts.size === 0) initValues = { default: true };
  /* Get Id of item, Try to convert Immutable to JS object or return null */
  else initValues = result(getContact(state, contactId), 'toJS', null);

  // Disallow unchecking default, as this will allow them to delete the record.
  const displayFields = initValues && initValues.default ? protectedFields : fields;

  return ({
    fields: displayFields,
    initialValues: initValues,
    shrinkLabels: true,
  });
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    create: createContactThunk,
    update: updateContactThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'contactForm',
})(GeneralForm)));

