import { result } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  withStyles,
  Button,
  Checkbox,
  Typography,
  Grid,
  Modal,
} from 'material-ui';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'material-ui/Table';
import { withRouter } from 'react-router-dom';
import {
  AccountBalance,
  Add,
} from 'material-ui-icons';

import ConfirmationDialog from '../../../components/confirmationDialog'
import { getPayments, getAutoPayId, getPaymentAuthorizationMessage } from '../selectors'
import CreditCardIcon from '../../../svgs/credit-card'
import { deletePaymentThunk, autoPayThunk } from '../reducer'
import { routePropTypes } from '../../../utils/routes';

const styles = theme => ({
  root: {
    width: 'calc(100% - 40px)',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  actions: {
    width: 180,
    textAlign: 'right',
  },
  divider: {
    padding: '0 20px',
  },
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  svg: {
    height: '2rem',
    width: '2rem',
    fill: 'rgba(0, 0, 0, 0.54)',
  },
  centerBottomButton: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '16px',
    marginBottom: '-16px',
  },
  linkButton: {
    color: 'blue',
    marginLeft: '-1rem',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      transform: 'translate(0, 0)',
      overflowY: 'auto',
      // Add space for floating "Live Chat" button
      paddingBottom: theme.spacing.unit * 10,
    },
  },
});

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPayId: props.autoPayId,
      autoPaySelectionId: null,
      autoPayConfirmationOpen: false,
      deleteConfirmationOpen: false,
      deleteItem: null,
      paymentAuthorizationUrl: null,
      showPaymentAuthorizationModal: false,
    };
  }

  componentDidMount() {
    document.title = this.props.title;
    if (this.props.paymentAuthorization && this.props.paymentAuthorization.file) {
      // eslint-disable-next-line
      this.state.paymentAuthorizationUrl = URL.createObjectURL(new Blob([this.props.paymentAuthorization.file], { type: 'text/html' }));
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      autoPayId: props.autoPayId,
    });
  }

  componentWillUnmount() {
    if (this.state.paymentAuthorizationUrl) {
      URL.revokeObjectURL(this.state.paymentAuthorizationUrl);
    }
  }

  closeDialogs = () => {
    this.setState({
      autoPayConfirmationOpen: false,
      deleteConfirmationOpen: false,
    });
  }

  togglePaymentAuthorizationModal = () => this.setState((state) =>
    ({ showPaymentAuthorizationModal: !state.showPaymentAuthorizationModal }));

  handleConfirmDelete = () => {
    this.setState({ deleteConfirmationOpen: false });
    this.props.actions.deletePaymentThunk(this.state.deleteItem.id);
  }

  confirmAutoPay = id => {
    this.setState({
      autoPaySelectionId: id,
      autoPayConfirmationOpen: true,
    });
  };

  updateAutoPay = () => {
    this.props.actions
      .autoPayThunk({
        payment_id: this.props.autoPayId === this.state.autoPaySelectionId
          ? null
          : this.state.autoPaySelectionId,
      })
      .catch(() => this.setState({ autoPayId: this.props.autoPayId }));
    this.setState({ autoPayConfirmationOpen: false });
  }

  renderRow = n => {
    const { classes } = this.props;
    const { autoPayId } = this.state;
    return (
      <TableRow key={n.id}>
        <TableCell>
          {n.profile_type === 'ach' ?
            <AccountBalance className={classes.svg} />
            :
            <CreditCardIcon className={classes.svg} />
          }
        </TableCell>
        <TableCell>{n.line}</TableCell>
        <TableCell>
          <Checkbox checked={n.id === autoPayId} onChange={() => this.confirmAutoPay(n.id)} />
        </TableCell>
        <TableCell className={classes.actions} >
          <Button
            size="small"
            color="secondary"
            onClick={() => this.setState({ deleteItem: n, deleteConfirmationOpen: true })}
          >
            Remove
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const {
      classes,
      payments,
      paymentAuthorization,
    } = this.props

    const link = this.state.paymentAuthorizationUrl ? (
      <Button
        className={classes.linkButton}
        onClick={this.togglePaymentAuthorizationModal}
      >
        Terms & Conditions
      </Button>
    ) : '';

    const message = (paymentAuthorization && paymentAuthorization.message) || 'By enabling AutoPay, you acknowledge that you have read and agree to the Red Dot Storage payment Terms & Conditions.';

    const modal = this.state.paymentAuthorizationUrl ? (
      <Modal open={this.state.showPaymentAuthorizationModal}>
        <Grid className={classes.modal}>
          <div className="modal-pdf-container">
            <iframe title="Terms & Conditions" src={this.state.paymentAuthorizationUrl} width="100%" height="100%" />
          </div>
          <Button className={classes.centerBottomButton} color="secondary" onClick={this.togglePaymentAuthorizationModal} raised size="large">Close</Button>
        </Grid>
      </Modal>
    ) : '';

    return (
      <div>
        { payments.length === 0 ?
          <Typography align="center">No Payment Methods available.</Typography>
          :
          (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Payment Information</TableCell>
                  <TableCell>AutoPay</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map(n => this.renderRow(n))}
              </TableBody>
            </Table>
          )
        }

        <div className="t-right">
          <Button
            fab
            mini
            onClick={() => this.props.history.push('/billing/payments/create')}
            color="secondary"
            className={classes.button}
          >
            <Add className="app-white-svg" />
          </Button>
        </div>

        <ConfirmationDialog
          open={this.state.deleteConfirmationOpen}
          onClose={this.closeDialogs}
          onConfirm={this.handleConfirmDelete}
          title="Confirm Delete"
          content={
            <Typography>{`You are about to delete ${(this.state.deleteItem || {}).line}. Are you sure?`}</Typography>
          }
        />
        <ConfirmationDialog
          maxWidth="sm"
          open={this.state.autoPayConfirmationOpen}
          onClose={this.closeDialogs}
          onConfirm={this.updateAutoPay}
          title={this.state.autoPayId === this.state.autoPaySelectionId ? 'Disable Auto-Pay?' : 'Enable Auto-Pay?'}
          content={
            <React.Fragment>
              {this.state.autoPayId === this.state.autoPaySelectionId ? '' : link}
              <Typography>{this.state.autoPayId === this.state.autoPaySelectionId ? `Are you sure you want to disable AutoPay for ${(payments.find(p => p.id === this.state.autoPaySelectionId) || {}).line}?` : message}</Typography>
            </React.Fragment>
          }
        />
        {modal}
      </div>
    );
  }
}

PaymentMethods.defaultProps = {
  autoPayId: null,
  payments: [],
  paymentAuthorization: null,
  title: 'Payment Methods',
};

PaymentMethods.propTypes = {
  autoPayId: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    line: PropTypes.node,
  })),
  paymentAuthorization: PropTypes.shape({
    file: PropTypes.string,
    message: PropTypes.string,
  }),
  title: PropTypes.string,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  payments: getPayments(state).toJS(),
  autoPayId: getAutoPayId(state),
  paymentAuthorization: result(getPaymentAuthorizationMessage(state), 'toJS', null),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    deletePaymentThunk,
    autoPayThunk,
  }, dispatch),
});

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethods)));
