import {
  takeLatest,
  put,
  call,
  all,
  select,
} from 'redux-saga/effects';
// import { push } from 'react-router-redux'
import { fetchUnit } from '../../services/reservation';
import { fetchFacility, fetchFacilityProtected } from '../../services/facility';
import { getLoggedIn } from '../Auth/selectors';
import { getFacility } from '../Facility/selectors';
import {
  constants,
  fetchUnitSuccess,
  fetchUnitError,
  fetchFacilitySuccess,
  fetchFacilityError,
} from './reducer';

function* fetchUnitSaga(action) {
  const { result, error } = yield call(fetchUnit, action.payload);
  if (error) {
    yield put(fetchUnitError(error));
  } else {
    // Fetch the facility data
    let facilityResponse;
    let facilityLoaded = false;
    if (yield select(getLoggedIn)) {
      facilityResponse = yield call(fetchFacilityProtected, result.facility_id);
      facilityLoaded = true;
    } else {
      const facility = yield select(getFacility);
      if (facility && facility.get('id').toString() === result.facility_id.toString()) {
        facilityResponse = { result: facility.toJS() };
      } else {
        facilityResponse = yield call(fetchFacility, result.facility_id);
      }
    }

    if (facilityResponse.error) {
      yield put(fetchFacilityError(facilityResponse.error));
    } else {
      // Temporary code as api returns null
      facilityResponse.result.payment_methods = facilityResponse.result.payment_methods || [];
      yield put(fetchFacilitySuccess({
        facility: facilityResponse.result,
        loaded: facilityLoaded,
      }));
    }

    yield put(fetchUnitSuccess(result));
  }
}

function* fetchFacilitySaga(action) {
  const { result, error } = yield call(fetchFacilityProtected, action.payload);
  if (error) {
    yield put(fetchFacilityError(error));
  } else {
    // Temporary code as api returns null
    result.payment_methods = result.payment_methods || [];
    yield put(fetchFacilitySuccess({ facility: result, loaded: true }));
  }
}

function* reservationSagas() {
  yield all([
    takeLatest(constants.FETCH_UNIT, fetchUnitSaga),
    takeLatest(constants.FETCH_FACILITY, fetchFacilitySaga),
  ]);
}

export default reservationSagas;
