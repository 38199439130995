/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { Link } from 'react-router-dom';
import Title from '../../components/Title';
import ContactForm from './helpContactForm';

const styles = (/* theme */) => ({
  bodyContent: {
    fontSize: '19px',
    padding: '0.5em 0',
  },
  footerContent: {
    fontSize: '19px',
    paddingBottom: '0.5em',
  },
  pageContainer: {
    gap: '1em',
  },
  privacyLink: {
    color: 'black',
    textDecoration: 'underline',
  },
  textContainer: {
    'text-align': 'center',
  },
});

const ContactUs = ({ classes, title }) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid className={classes.pageContainer} container justify="center">
      <Title>
        <Typography type="display4">RED DOT STORAGE</Typography>
        <Typography type="display2">CONTACT US</Typography>
      </Title>
      <Grid container justify="center">
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8} xl={8}>
          <Typography className={classes.bodyContent} type="body1">Looking to speak with a member on our team? Either give us a call, chat or submit the form below and someone at Red Dot Storage will follow up with you shortly.</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
        <ContactForm />
      </Grid>
      <Grid container justify="center">
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8} xl={8}>
          <Typography className={classes.footerContent} type="body1">To learn more about how we use the information you send us, please see our <Link className={classes.privacyLink} to="/privacy-policy">privacy policy</Link>.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
ContactUs.defaultProps = {
  title: 'Contact Us',
};
ContactUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default withStyles(styles)(ContactUs);
