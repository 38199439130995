import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchUnits(magicKey) {
  return yield call(
    fetchData,
    '/api/customer/v1/account/facilities',
    {
      method: 'GET',
      headers: magicKey ? { 'X-USER-MAGIC-KEY': magicKey } : undefined,
    },
  );
}

export function moveOut(data) {
  return fetchData(
    '/api/customer/v1/moveout',
    {
      method: 'POST',
      body: data,
    },
  );
}

export function cancelMoveOut(data) {
  return fetchData(
    '/api/customer/v1/moveout',
    {
      method: 'DELETE',
      body: data,
    },
  );
}
