import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  withStyles,
} from 'material-ui';
import {
  AirportShuttle,
  DirectionsBike,
  DirectionsBoat,
  DirectionsCar,
  LocalParking,
  RvHookup,
} from 'material-ui-icons';
import moment from 'moment';

import { result } from 'lodash';

import GeneralForm from '../../../components/Form';
import SelectField from '../../../components/Form/selectField';
import AutoMakeField from '../../../components/Form/autoMakeField';
import { addVehicleThunk, createVehicleThunk, updateVehicleThunk } from '../reducer';
import { getCustomerInsurances, getVehicle, getVehicles } from '../selectors';
import {
  minLength,
  required as requiredValidation,
  vin as vinValidation,
  year as yearValidation,
} from '../../../utils/validations';
import { getAccountId } from '../../Auth/selectors';
import { phoneMask, phoneFormat } from '../../../constants';
import InsuranceForm from './insuranceForm';
import { getOccupiedUnits } from '../../MyUnits/selectors';


const minPhoneLength = minLength(11, 'Phone Number must contain at least 10 digits', /\D/g);

const defaultFields = [{
  custom: (
    <FormControl fullWidth margin="normal" key="type">
      <InputLabel className="required" htmlFor="type" required shrink>Vehicle Type</InputLabel>
      <Field
        name="type"
        component={SelectField}
        validate={requiredValidation}
      >
        <MenuItem value="ATV">ATV</MenuItem>
        <MenuItem value="Automobile">Automobile</MenuItem>
        <MenuItem value="Boat">Boat</MenuItem>
        <MenuItem value="Motor Bike / Scooter">Motor Bike / Scooter</MenuItem>
        <MenuItem value="Personal Watercraft">Personal Watercraft</MenuItem>
        <MenuItem value="RV">RV</MenuItem>
        <MenuItem value="Trailer">Trailer</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
      </Field>
    </FormControl>
  ),
}, {
  custom: (
    <Field
      fullWidth
      required
      component={AutoMakeField}
      containerWidth="50%"
      InputLabelProps={{ className: 'required', shrink: true }}
      name="make"
      validate={requiredValidation}
    />
  ),
}, {
  label: 'Model',
  name: 'model',
  required: true,
  validate: requiredValidation,
}, {
  label: 'Year',
  name: 'year',
  required: true,
  validate: yearValidation,
}, {
  label: 'Color',
  name: 'color',
  required: true,
  validate: requiredValidation,
}, {
  label: 'Serial Number (VIN)',
  name: 'vin',
  required: true,
  validate: vinValidation,
}, {
  label: 'License Plate #',
  name: 'license_plate',
  required: true,
  validate: requiredValidation,
}, {
  fullWidth: true,
  label: 'Description',
  name: 'lien_property_description',
}, {
  fullWidth: true,
  label: 'Lienholder / Secured Creditor',
  name: 'lienholder',
}, {
  fullWidth: true,
  label: 'Lienholder Phone #',
  name: 'lienholder_phone',
  mask: phoneMask,
  pipe: phoneFormat,
  validate: minPhoneLength,
}, {
  fullWidth: true,
  label: 'Lienholder Address',
  name: 'lienholder_address',
}];
const requiredFields = defaultFields.map(field => (field.label === 'Description' ? { ...field, required: true } : field));

const styles = (theme) => ({
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      transform: 'translate(0, 0)',
      overflowY: 'auto',
      // Add space for floating "Live Chat" button
      paddingBottom: theme.spacing.unit * 10,
    },
  },
});

const VehicleForm = reduxForm({ enableReinitialize: true, form: 'vehicleForm' })(GeneralForm);
const vehicleFormWrapper = withStyles(styles)(({
  classes,
  fields,
  insurances,
  units,
  vehicles,
  ...formProps
}) => {
  const insuranceCount = useRef(insurances && insurances.size);

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const expiredValidation = useCallback((id) => {
    const insurance = insurances.find(i => i.get('id') === id);
    if (!insurance) {
      return 'Invalid Insurance selected';
    } else if (insurance.get('end_date') && moment(insurance.get('end_date')).isBefore(moment(), 'day')) {
      return 'Insurance expired';
    }
    return undefined;
  }, [insurances]);

  const formFields = useMemo(() => {
    const overrideValue = typeof insuranceCount.current === 'number' && insurances.size > insuranceCount.current
      ? insurances.last().get('id')
      : undefined;
    insuranceCount.current = insurances && insurances.size;


    return (
      formProps.isCreate ? fields : fields.concat([{
        custom: (
          <FormControl fullWidth margin="normal" key="customer_insurance_id">
            <InputLabel className="required" htmlFor="customer_insurance_id" required shrink>Insurance</InputLabel>
            <Field
              name="customer_insurance_id"
              component={SelectField}
              onChange={(e, value) => {
                // Handle when "New" is selected
                if (value === 'NEW') {
                  setModalOpen(true);
                  e.preventDefault();
                }
              }}
              overrideValue={overrideValue}
              validate={[requiredValidation, expiredValidation]}
            >
              {!!insurances && insurances.map((insurance) => <MenuItem disabled={!!insurance.get('end_date') && moment(insurance.get('end_date')).isBefore(moment(), 'day')} value={insurance.get('id')}>{insurance.get('carrier_name')}</MenuItem>)}
              <MenuItem value="NEW">New</MenuItem>
            </Field>
          </FormControl>
        ),
      }])
    );
  }, [expiredValidation, formProps.isCreate, fields, insurances]);

  const formFieldsWithOccupancy = useMemo(() => {
    const myUnits = result(units, 'toJS', []);
    const myVehicles = result(vehicles, 'toJS', []);
    const NO_UNIT = 'NO_UNIT';
    const takenUnits = [...new Set(myVehicles.map(vehicle => vehicle.occupancy_id))];
    const myUnitsWithParking = myUnits.filter(unit => unit.unit_type_name.includes('Parking'));
    const myUnitsWithParkingVacancies =
      myUnitsWithParking.filter(unit => !takenUnits.includes(unit.occupancy_id));
    const defaultSelection = {
      value: formProps.initialValues.occupancy_id || NO_UNIT,
      currentUnit: myUnitsWithParking
        .find(unit => unit.occupancy_id === formProps.initialValues.occupancy_id),
    }
    return (
      (myUnitsWithParkingVacancies.length > 0) ? formFields.concat([
        {
          custom: (
            <FormControl fullWidth margin="normal" key="occupancy_id">
              <InputLabel className="required" htmlFor="occupancy_id" required shrink>Unit</InputLabel>
              <Field
                key="occupancy_id"
                name="occupancy_id"
                component={SelectField}
                validate={requiredValidation}
              >
                {myUnitsWithParkingVacancies.map(unit => (
                  <MenuItem key={unit.occupancy_id} value={unit.occupancy_id}>
                    {unit.unit_number}
                  </MenuItem>
                ))}
                <MenuItem key="default-unit" value={defaultSelection.value}>
                  {(defaultSelection.currentUnit === undefined) ? 'Not in unit' : defaultSelection.currentUnit.unit_number}
                </MenuItem>
              </Field>
            </FormControl>
          ),
        },
      ]) : formFields
    );
  }, [formFields, units, vehicles, formProps]);

  return (
    <React.Fragment>
      <VehicleForm fields={formFieldsWithOccupancy} {...formProps} />
      <Modal open={modalOpen}>
        <Grid className={classes.modal}>
          <InsuranceForm
            isCreate
            onCancel={closeModal}
            onSubmitComplete={closeModal}
            title="Add Auto Insurance"
          />
        </Grid>
      </Modal>
    </React.Fragment>
  );
});

const formSelector = formValueSelector('vehicleForm');
const mapStateToProps = (state, ownProps) => {
  const occupiedUnits = getOccupiedUnits(state);
  const vehicleState = {
    type: 'Automobile', owner_type: 'Account', owner_id: getAccountId(state), occupancy_id: 'NO_UNIT',
  };

  // If we're editing an existing Vehicle, pre-populate values from store
  if (ownProps.match && ownProps.match.params && ownProps.match.params.itemId) {
    Object.assign(vehicleState, result(getVehicle(state, ownProps.match.params.itemId), 'toJS', null));
    if (vehicleState.CustomerInsurances && vehicleState.CustomerInsurances.length > 0) {
      vehicleState.customer_insurance_id = vehicleState.CustomerInsurances[0].id;
      delete vehicleState.CustomerInsurances;
    }
  }

  return {
    fields: formSelector(state, 'type') === 'Other' ? requiredFields : defaultFields,
    insurances: getCustomerInsurances(state),
    initialValues: vehicleState,
    shrinkLabels: true,
    units: occupiedUnits,
    vehicles: getVehicles(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    create: ownProps.isCreate ? addVehicleThunk : createVehicleThunk,
    update: updateVehicleThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
});

// Decorate with redux-form
export default connect(mapStateToProps, mapDispatchToProps)(vehicleFormWrapper);

const VEHICLE_ICONS = {
  ATV: DirectionsBike,
  Automobile: DirectionsCar,
  Boat: DirectionsBoat,
  'Motor Bike / Scooter': DirectionsBike,
  'Personal Watercraft': DirectionsBoat,
  RV: AirportShuttle,
  Trailer: RvHookup,
};
export const getVehicleIcon = (type) => (VEHICLE_ICONS[type] || LocalParking);

export const getVehicleLabel = (vehicle) => {
  const parts = [];
  if (vehicle.year) parts.push(vehicle.year);
  else if (vehicle.color) parts.push(vehicle.color);
  if (vehicle.make || vehicle.model) {
    if (vehicle.make) parts.push(vehicle.make);
    if (vehicle.model) parts.push(vehicle.model);
  } else {
    parts.push(vehicle.type);
  }
  return parts.join(' ');
}
