const ThemeStyle = theme => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    position: 'relative',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'table',
    },
  },
  bottomDiv: {
    width: '100%',
    marginTop: 12,
  },
  cancel: {
    marginRight: 12,
  },
  errorWrapper: {
    color: theme.palette.secondary.main,
    fontSize: '0.9rem',
    paddingRight: '12px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      overflowWrap: 'break-word',
    },
  },
  row: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  title: {
    fontSize: '30px',
  },
})

export default ThemeStyle
