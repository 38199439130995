import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Snackbar,
  SnackbarContent,
  withStyles,
} from 'material-ui';
import Fade from 'material-ui/transitions/Fade';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import { getAppMsg } from '../../containers/AppWrapper/selectors';

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  root: {
    width: '100vw',
    marginTop: theme.spacing.unit,
    zIndex: 1200,
    position: 'relative',
  },
  contentRoot: {
    width: '100%',
    maxWidth: '100vw',
    color: theme.palette.secondary.main,
    backgroundColor: 'white',
  },
  contentMessage: {
    flexGrow: 1,
    textAlign: 'center',
  },
});

class MessageBox extends React.Component {
  state = {
    open: false,
  };

  componentWillReceiveProps(props) {
    this.setState({ open: !!props.msg.content });
    clearTimeout(this.timerAutoHide);
    if (props.msg.content && typeof props.msg.duration === 'number') {
      this.timerAutoHide = setTimeout(() => {
        this.setState({ open: false })
      }, props.msg.duration);
    }
  }

  timerAutoHide = null;

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  }

  render() {
    const { classes, msg } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        onClose={this.handleClose}
        transition={Fade}
        SnackbarContentProps={{
          'aria-describedby': 'message-id',
        }}
        classes={{
          root: classes.root,
        }}
      >
        <SnackbarContent
          classes={{
            root: classes.contentRoot,
            message: classes.contentMessage,
          }}
          message={<span id="message-id">{msg.content}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

MessageBox.propTypes = {
  classes: PropTypes.object.isRequired,
  msg: PropTypes.shape({
    content: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  msg: getAppMsg(state),
});

export default withStyles(styles)(connect(mapStateToProps)(MessageBox));
