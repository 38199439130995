import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'material-ui';
import { Lock } from 'material-ui-icons';
import Logo from '../../images/unit/iconUnit.png';

const UnitImage = (props) => {
  const { locked, ...imgProps } = props;
  if (imgProps.onClick) imgProps.style = { cursor: 'pointer' };
  let img = <img src={Logo} alt="Unit" {...imgProps} />;
  if (locked) img = <Badge badgeContent={<Lock />}>{img}</Badge>;
  return img;
};

UnitImage.defaultProps = {
  locked: false,
};

UnitImage.propTypes = {
  locked: PropTypes.bool,
};

export default UnitImage;
