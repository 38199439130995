const constructRequest = (url, data) => {
  const params = new URLSearchParams(data);
  // Strip special characters from phone #
  params.set('tel', params.get('tel').replace(/\D/g, ''));
  return new Request(url, {
    method: 'POST',
    body: params.toString(),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
}

const parseJSONResponse = (response) => (
  response.json().then(({ result }) => {
    // Extract string from HTML response
    const div = document.createElement('div');
    div.innerHTML = result;
    return response.ok
      ? { result: div.textContent }
      : { error: { _error: div.textContent } };
  })
);

export function sendContactMessage(data) {
  return fetch(constructRequest('https://client-leads.g5marketingcloud.com/api/v1/locations/g5-cl-1klhzt76ti-rd-corporate/html_forms/contact/submissions', data))
    .then(parseJSONResponse)
    .catch(() => ({ error: { _error: 'Request was not successful.' } }));
}

export function sendAcquisitionMessage(data) {
  return fetch(constructRequest('https://client-leads.g5marketingcloud.com/api/v1/locations/g5-cl-1klhzt76ti-rd-corporate/html_forms/contact_name_phone_email_message/submissions', data))
    .then(parseJSONResponse)
    .catch(() => ({ error: { _error: 'Request was not successful.' } }));
}
