/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { result } from 'lodash';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Typography,
  RadioGroup,
  Radio,
  TextField,
  withStyles,
  Hidden,
  Select,
  MenuItem,
} from 'material-ui';

import { AddCircleOutline } from 'material-ui-icons';

import NewPaymentForm from './NewPaymentForm';
import Card from '../../Billing/PaymentMethods/PaymentForm/Card';
import AutocompleteField from '../../../components/Form/autocompleteField';
import AutoInsuranceField from '../../../components/Form/autoInsuranceField';
import CurrencyField from '../../../components/Form/currencyField';
import Loading from '../../../components/Loading';
import {
  createMoveInThunk,
  createPaymentThunk,
  fetchMoveInCostsThunk,
  saveMoveInState,
  getChargeItems,
  fetchMoveInCostError,
} from '../reducer';
import {
  transactSavedDataThunk,
  fetchVehiclesThunk,
  createCustomerInsuranceThunk,
  updateCustomerInsuranceThunk,
  assignCustomerInsuranceThunk,
} from '../../Account/reducer';
import { fetchPaymentAuthorizationMessageThunk } from '../../Billing/reducer';
import { getPaymentAuthorizationMessage } from '../../Billing/selectors';
import { getFacility, getMoveIn, getMoveInCosts, getMoveInCostError, getMoveInState, getUnit } from '../selectors';
import CreditCardIcon from '../../../svgs/credit-card';
import { currency } from '../../../utils/format';
import { containsAllValues } from '../../../utils/object';
import { appMsg, setReturnUrl } from '../../AppWrapper/reducer';
import LeaseAgreement from '../../LeaseAgreement';
import { getAccountId, getLoggedIn } from '../../Auth/selectors';
import { getAvailableVehicles, getCustomerInsurances, getFetching, getLoaded } from '../../Account/selectors';
import VehicleForm, { getVehicleIcon, getVehicleLabel } from '../../Account/RegisterVehicle/vehicleForm';
import './styles.css';

const Lease = withRouter(LeaseAgreement);

const styles = (theme) => ({
  container: {
    width: 640,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 354,
    },
  },
  button: {
    marginRight: '4px',
  },
  subItem: {
    paddingTop: '0.5em',
  },
  radio: {
    width: 32,
    height: 32,
    margin: '0px 8px',
  },
  radioLabel: {
    paddingBottom: '0.5em',
  },
  radioTextContainer: {
    width: '100%',
    margin: 0,
  },
  radioSubtext: {
    fontSize: '12px',
  },
  buttonGroup: {
    textAlign: 'right',
    marginBottom: 80,
    position: 'relative',
    marginLeft: 'auto',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      transform: 'translate(0, 0)',
      overflowY: 'auto',
      // Add space for floating "Live Chat" button
      paddingBottom: theme.spacing.unit * 10,
    },
  },
  svg: {
    height: '1.5rem',
    width: '1.5rem',
    fill: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'bottom',
    marginRight: 10,
  },
  inlineIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '5px',
  },
  centerBottomButton: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '16px',
    marginBottom: '-16px',
  },
  insuranceSelect: {
    backgroundColor: '#DEDEDE',
    border: '1px solid gray',
  },
  linkButton: {
    color: 'blue',
    marginLeft: '1rem',
  },
});

const isEmpty = (str) => !str || /^\s+$/.test(str);

const getHtmlBlob = (html) => new Blob([html], { type: 'text/html' });

const HOME_INSURANCE_CARRIERS = [
  { label: 'State Farm' },
  { label: 'Allstate' },
  { label: 'Liberty Mutual Group' },
  { label: 'USAA' },
  { label: 'Farmers Insurance Group' },
  { label: 'Travelers' },
  { label: 'Nationwide Mutual Group' },
  { label: 'American Family Insurance' },
  { label: 'Chubb' },
  { label: 'Erie Insurance' },
  { label: 'Auto-Owners Insurance' },
  { label: 'American International Group (AIG)' },
  { label: 'MetLife' },
  { label: 'Progressive' },
  { label: 'Hartford Financial Services' },
  { label: 'Universal Insurance Holdings' },
  { label: 'CSAA Insurance Exchance' },
  { label: 'Amica Mutual Insurance' },
  { label: 'Auto Club Exchange' },
  { label: 'Heritage Insurance' },
  { label: 'United Insurance Holdings' },
  { label: 'COUNTRY Financial' },
  { label: 'Auto Club Insurance Association' },
  { label: 'National General Holdings' },
  { label: 'Tower Hill' },
  { label: 'Assurant' },
  { label: 'Hanover Insurance Group' },
  { label: 'Cincinnati Financial' },
  { label: 'Munich Re' },
  { label: 'QBE' },
  { label: 'MAPFRE SA' },
  { label: 'FEDNAT' },
  { label: 'Citizens Property Insurance' },
  { label: 'PURE' },
  { label: 'Mercury General' },
  { label: 'Shelter Insurance' },
  { label: 'Tennessee Farmers Insurance' },
  { label: 'Ameriprise Financial' },
  { label: 'State Auto Insurance' },
  { label: 'First Protective Insurance' },
  { label: 'Security First Insurance' },
  { label: 'North Carolina Farm Bureau Insurance' },
  { label: 'Andover Companies' },
  { label: 'Farm Bureau Financial Services' },
  { label: 'Florida Peninsula Holdings' },
  { label: 'Universal Group' },
  { label: 'HCI Group' },
  { label: 'Alfa Mutual Group' },
  { label: 'American National Insurance' },
  { label: 'Southern Farm Bureau Casualty' },
];

class MoveIn extends Component {
  constructor(props) {
    super(props);

    this.state = props.moveInState || {
      insuranceValue: 'not_selected',
      paymentValue: 'not_selected',
      selectedVehicleIndex: -1,
      customerInsuranceValue: 'NEW',
      customerInsuranceFormValues: {},
      carrier: '',
      autoPay: true,
      paymentAuthorized: false,
      paymentAuthorizationUrl: '',
      promoCode: '',
      promotion_code: '',
      showPaymentAuthorizationModal: false,
      showPaymentModal: false,
      showInsuranceModal: false,
      showLeaseModal: false,
      showVehicleModal: false,
      submitting: false,
      submitClicked: false,
      validated: false,
      transacting: false,
    };

    this.applyPromotionCode = this.applyPromotionCode.bind(this);
  }

  componentDidMount() {
    document.title = this.props.title;

    // If unit is present and we don't currently have move-in costs, fetch them now
    this.updateMoveInCosts = !this.props.moveInCosts && !!this.props.unit;
    this.refreshMoveInCosts();
    this.fetchVehicles();

    if (
      this.state.validated
      && !this.state.showLeaseModal
      && !this.state.transacting) {
      if (this.props.loggedIn) {
        // eslint-disable-next-line
        this.state.transacting = true;
        this.transactSavedData(this.props);
      } else {
        // eslint-disable-next-line
        this.state.validated = false;
      }
    } else if (!this.state.paymentAuthorized) {
      // Fetch payment authorization message on load
      this.props.actions.fetchPaymentAuthorizationMessage();
    }

    if (this.props.paymentAuthorization && this.props.paymentAuthorization.file) {
      // eslint-disable-next-line
      this.state.paymentAuthorizationUrl = URL
        .createObjectURL(getHtmlBlob(this.props.paymentAuthorization.file));
    }
  }

  shouldComponentUpdate(props, state) {
    // If unit has changed, fetch new move-in costs once props are updated
    let shouldUpdate = true;
    if (props.unit) {
      if (
        !this.props.unit ||
        props.unit.id !== this.props.unit.id ||
        props.unit.facility_id !== this.props.unit.facility_id
      ) {
        this.updateMoveInCosts = true;
      }
    } else if (this.props.unit) {
      this.updateMoveInCosts = true;
    }
    if (state.promotion_code !== this.state.promotion_code) {
      this.updateMoveInCosts = true;
    }

    // Auto-select the new payment method if one has been added to this facility
    if (
      props.facility &&
      this.props.facility &&
      props.facility.id === this.props.facility.id &&
      props.facility.payment_methods.length >
      this.props.facility.payment_methods.length
    ) {
      const newPayment = props.facility.payment_methods[props.facility.payment_methods.length - 1];
      this.setState({
        paymentValue: newPayment.id.toString(),
        showPaymentModal: false,
      });

      shouldUpdate = false;
    }

    // Auto-select last Vehicle on load / when a new Vehicle is added
    if (props.vehicles && props.vehicles.length > 0 && (!this.props.vehicles
      || props.vehicles.length !== this.props.vehicles.length || state.selectedVehicleIndex < 0)
    ) {
      let insurance = props.vehicles[props.vehicles.length - 1].CustomerInsurances;
      insurance = insurance && insurance.length > 0 ? insurance[0] : undefined;

      this.setState({
        selectedVehicleIndex: props.vehicles.length - 1,
        customerInsuranceValue: insurance ? insurance.id : 'NEW',
        customerInsuranceFormValues: insurance || {},
        vehicleExpireDate: insurance && moment(insurance.end_date),
        showVehicleModal: false,
      });

      shouldUpdate = false;
    }

    if (state.validated) {
      if (!props.loggedIn) {
        // User is not logged in yet; save move-in state and redirect to login
        props.actions.setReturnUrl(true);
        props.history.push('/', { createAccount: true });
      } else if (!state.transacting && !state.showLeaseModal) {
        this.transactSavedData(props);
        this.setState({ transacting: true });
      }

      shouldUpdate = false;
    }

    if (!state.paymentAuthorizationUrl
      && props.paymentAuthorization && props.paymentAuthorization.file) {
      this.setState({
        paymentAuthorizationUrl: URL.createObjectURL(getHtmlBlob(props.paymentAuthorization.file)),
      });
    }

    return shouldUpdate;
  }

  componentDidUpdate() {
    this.refreshMoveInCosts();
    this.fetchVehicles();
  }

  componentWillUnmount() {
    // Save Move-In state and clear error message
    this.props.actions.saveMoveInState(this.state);
    this.props.actions.appMsg(null);
    if (this.state.paymentAuthorizationUrl) {
      URL.revokeObjectURL(this.state.paymentAuthorizationUrl);
    }
  }

  getMoveInCosts = (insuranceId) => {
    const { moveInCostError, unit } = this.props;

    let { insuranceValue } = this.state;
    if (typeof insuranceId !== 'undefined') insuranceValue = insuranceId;

    const payload = {
      unit_id: unit.id,
      insurance_id: insuranceValue,
      promotion_id: unit.promotion_id,
    };

    if (this.state.promotion_code && !moveInCostError) {
      payload.promotion_code = this.state.promotion_code;
    }

    // console.log('Should be fetching moveincosts here');
    this.props.actions.fetchMoveInCosts(payload);
  };

  transactSavedData(props) {
    const self = this;
    return props.actions.transactSavedData().then(() => {
      self.setState({ validated: false, transacting: false, showLeaseModal: true });
    });
  }

  fetchVehicles() {
    const {
      accountId,
      actions,
      fetchingVehicles,
      loggedIn,
      unit,
      vehiclesLoaded,
    } = this.props;

    if (loggedIn && unit && unit.unit_type && unit.unit_type.startsWith('parking') && !vehiclesLoaded && !fetchingVehicles) {
      actions.fetchVehicles(accountId);
    }
  }

  refreshMoveInCosts() {
    if (this.updateMoveInCosts && this.props.unit) {
      this.getMoveInCosts();
      this.updateMoveInCosts = false;
    }
  }

  handleInsuranceChange = (e) => {
    this.setState({ insuranceValue: e.target.value });
    this.getMoveInCosts(e.target.value);
  };

  handlePaymentChange = (e) => {
    this.setState({ paymentValue: e.target.value });
  };

  handleVehicleChange = (e) => {
    const { vehicles } = this.props;
    let insurance = vehicles[e.target.value].CustomerInsurances;
    insurance = insurance && insurance.length > 0 ? insurance[0] : null;
    this.setState({
      selectedVehicleIndex: parseInt(e.target.value, 10),
      customerInsuranceValue: insurance ? insurance.id : 'NEW',
      customerInsuranceFormValues: { ...insurance },
      vehicleExpireDate: insurance ? moment(insurance.end_date) : undefined,
    });
  }

  handleCustomerInsuranceChange = (e) => {
    const { customerInsurance } = this.props;
    const insurance = customerInsurance.find((i) => i.id === e.target.value);
    this.setState({
      customerInsuranceValue: e.target.value,
      customerInsuranceFormValues: { ...insurance },
      vehicleExpireDate: insurance ? moment(insurance.end_date) : undefined,
    });
  }

  applyPromotionCode() {
    const { actions } = this.props;
    const { promoCode } = this.state;

    // Clear any previous error
    actions.fetchMoveInCostError('');

    // Apply the current promotion code
    this.setState({ promotion_code: promoCode });
  }

  validateMoveIn = () => {
    const {
      facility,
      moveInCostError,
      moveInCosts,
      unit,
    } = this.props;
    const isParking = unit.unit_type && unit.unit_type.startsWith('parking');

    const {
      paymentValue,
      insuranceValue,
      selectedVehicleIndex,
      customerInsuranceFormValues,
    } = this.state;

    let parkingInvalid = false;
    if (isParking) {
      parkingInvalid = selectedVehicleIndex < 0
        || isEmpty(customerInsuranceFormValues.carrier_name)
        || isEmpty(customerInsuranceFormValues.policy_number)
        || !this.state.vehicleExpireDate
        || this.state.vehicleExpireDate.isBefore(moment(), 'day');
    }

    if (paymentValue === 'not_selected' || parkingInvalid || (!isParking && insuranceValue === 'not_selected') ||
      (insuranceValue === 'home_owner' && (isEmpty(this.state.carrier) || isEmpty(this.state.policyNumber) || isEmpty(this.state.coverageAmount) || !this.state.expireDate))) {
      this.setState({ submitClicked: true, validated: false });
      return;
    }

    window.gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: Number(moveInCosts.charges.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.amount),
        0.0,
      )).toFixed(2),
      coupon: moveInCostError ? '' : this.state.promotion_code,
      items: getChargeItems({
        facility_id: unit.facility_id,
        unit_id: unit.id,
        insurance_id: insuranceValue,
      }, moveInCosts.charges, unit, facility),
    });

    this.setState({ submitClicked: true, validated: true });
  };

  completeMoveIn = (leaseEnvelopeId) => {
    const {
      actions,
      customerInsurance,
      moveInCostError,
      unit,
      vehicles,
    } = this.props;

    const {
      paymentValue,
      autoPay,
      insuranceValue,
      selectedVehicleIndex,
      customerInsuranceValue,
      customerInsuranceFormValues,
      vehicleExpireDate,
    } = this.state;

    const payload = {
      facility_id: unit.facility_id,
      unit_id: unit.id,
      insurance_id: insuranceValue,
      promotion_id: unit.promotion_id,
      lease_envelope_id: leaseEnvelopeId,
    };

    if (paymentValue === '-1') {
      payload.skip_payment = true;
    } else {
      payload.payment_id = paymentValue;
      payload.auto_pay = autoPay;
    }

    if (insuranceValue === 'home_owner') {
      payload.home_owner_insurance_attributes = {
        description: this.state.carrier,
        policy_number: this.state.policyNumber,
        coverage_amount: this.state.coverageAmount,
        end_date_str: this.state.expireDate.format('MM/DD/YYYY'),
      };
    }

    if (this.state.promotion_code && !moveInCostError) {
      payload.promotion_code = this.state.promotion_code;
    }

    // Create / Update CustomerInsurance and associate it with selected Vehicle
    let vehicleInsurancePromise;
    if (unit.unit_type && unit.unit_type.startsWith('parking') && selectedVehicleIndex >= 0) {
      const vehicleInsuranceFormValues = { ...customerInsuranceFormValues, end_date: vehicleExpireDate.format('MM/DD/YYYY') };
      const selectedInsurance = customerInsurance.find(i => i.id === customerInsuranceValue);
      if (customerInsuranceValue === 'NEW') {
        vehicleInsurancePromise = actions.createCustomerInsurance(vehicleInsuranceFormValues);
      } else if (!containsAllValues(selectedInsurance, customerInsuranceFormValues) || !vehicleExpireDate.isSame(selectedInsurance.end_date, 'day')) {
        vehicleInsurancePromise = actions.updateCustomerInsurance(vehicleInsuranceFormValues);
      } else {
        vehicleInsurancePromise = Promise.resolve({ id: customerInsuranceValue });
      }

      const vehicleInsurance = vehicles[selectedVehicleIndex].CustomerInsurances;
      if (!vehicleInsurance || vehicleInsurance.length <= 0
        || vehicleInsurance[0].id !== customerInsuranceValue) {
        vehicleInsurancePromise.then(({ id }) => (
          actions.assignCustomerInsurance({
            id: vehicles[selectedVehicleIndex].id,
            customer_insurance_id: id,
          })
        ))
      }

      payload.vehicle_id = vehicles[selectedVehicleIndex].id;
    } else {
      vehicleInsurancePromise = Promise.resolve();
    }

    this.setState({ submitting: true, showLeaseModal: false });
    vehicleInsurancePromise
      .then(() => actions.moveIn(payload))
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  showInsuranceModal = () => this.setState({ showInsuranceModal: true });
  hideInsuranceModal = () => this.setState({ showInsuranceModal: false });
  hideLeaseModal = () => this.setState({ showLeaseModal: false });
  showPaymentAuthorizationModal = () => this.setState({ showPaymentAuthorizationModal: true });
  hidePaymentAuthorizationModal = () => this.setState({ showPaymentAuthorizationModal: false });

  renderTenantInsurances = () => {
    const {
      classes,
      facility: { email, insurances },
    } = this.props;

    const otherLabel = (
      <Typography className="radio-label">
        I have my own policy
        <i>
          (Tenants are required to email a copy of their HOI Declaration page to{' '}
          <a className="link" href={`mailto:${email}`} rel="noreferrer" target="_blank">{email}</a>)
        </i>
      </Typography>
    );

    const {
      submitClicked,
      insuranceValue,
      carrier,
      policyNumber,
      coverageAmount,
      expireDate,
      expireDateStr,
    } = this.state;
    const isInsuranceError = submitClicked && insuranceValue === 'not_selected';
    const isCustomInsuranceError = submitClicked && insuranceValue === 'home_owner' &&
      (isEmpty(carrier) || isEmpty(policyNumber) || isEmpty(coverageAmount) || !expireDate);

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography color={isInsuranceError || isCustomInsuranceError ? 'secondary' : 'default'} type="headline" gutterBottom>
            <b>Tenant Protection Plan </b>
            <Button color="secondary" onClick={this.showInsuranceModal} size="small">What&apos;s covered?</Button>
          </Typography>
          <Typography type="body1">
            Select one of the coverage options for your new unit below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={isInsuranceError} fullWidth>
            <RadioGroup
              name="insurance"
              className={classes.group}
              value={insuranceValue}
              onChange={this.handleInsuranceChange}
            >
              {insurances.map((ins) => (
                <FormControlLabel
                  key={ins.id}
                  value={`${ins.id}`}
                  className={classes.radioLabel}
                  control={<Radio className={classes.radio} />}
                  label={
                    <Grid className={classes.radioTextContainer} container direction="column">
                      <Typography type="body1">{`${ins.name} (${currency(ins.premium)}/mo)`}</Typography>
                      <Typography type="body1" className={classes.radioSubtext}>{`Max ${currency(ins.coverage, { minimumFractionDigits: 0 })} Coverage`}</Typography>
                    </Grid>
                  }
                  margin="normal"
                />
              ))}
              <FormControlLabel
                value="home_owner"
                control={<Radio className={classes.radio} />}
                label={otherLabel}
              />
            </RadioGroup>
            { isInsuranceError && (
              <FormHelperText error>You must select a Protection Plan</FormHelperText>
            ) }
          </FormControl>
        </Grid>
        {insuranceValue === 'home_owner' && (
          <Grid item xs={12} md={8} className="own-policy-block">
            <AutocompleteField
              suggestions={HOME_INSURANCE_CARRIERS}
              margin="dense"
              fullWidth
              className={classes.textField}
              label="Carrier"
              value={carrier}
              onChange={(newValue) => {
                this.setState({ carrier: newValue });
              }}
              error={submitClicked && isEmpty(carrier)}
              FormHelperTextProps={{ error: true }}
              helperText={submitClicked && isEmpty(carrier) ? 'You must enter a valid Insurance Carrier' : ''}
            />
            <TextField
              margin="dense"
              fullWidth
              className={classes.textField}
              label="Policy Number"
              value={policyNumber}
              onChange={(e) => this.setState({ policyNumber: e.target.value })}
              error={submitClicked && isEmpty(policyNumber)}
              FormHelperTextProps={{ error: true }}
              helperText={submitClicked && isEmpty(policyNumber) ? 'You must enter a valid Policy Number' : ''}
            />
            <TextField
              margin="dense"
              fullWidth
              className={classes.textField}
              label="Coverage Amount"
              InputProps={{
                inputComponent: CurrencyField,
              }}
              // eslint-disable-next-line
              inputProps={{
                min: 1,
                max: 999999999.99,
              }}
              value={coverageAmount}
              onChange={(e) => this.setState({ coverageAmount: e.target.value })}
              error={submitClicked && isEmpty(coverageAmount)}
              FormHelperTextProps={{ error: true }}
              helperText={submitClicked && isEmpty(coverageAmount) ? 'You must enter a valid Coverage Amount' : ''}
            />
            <DatetimePickerTrigger
              shortcuts={{
                '< Previous Year': moment.max(moment(), moment(expireDate).subtract(1, 'years')),
                Clear: undefined,
                'Next Year >': moment(expireDate).add(1, 'years'),
              }}
              showTimePicker={false}
              className={
                expireDate
                  ? 'popup-calendar'
                  : 'popup-calendar not-selected'
              }
              onChange={(m) => this.setState({ expireDate: m })}
              minDate={moment()}
              moment={expireDate || moment()}
            >
              <TextField
                margin="dense"
                fullWidth
                className={classes.textField}
                label="Expiration Date"
                value={expireDateStr || (expireDate && expireDate.format('MM/DD/YYYY')) || ''}
                onChange={(e) => this.setState({ expireDateStr: e.target.value })}
                onBlur={() => {
                  if (expireDateStr) {
                    const date = moment(expireDateStr);
                    if (date.isValid() && date.isSameOrAfter(moment())) {
                      // Set expireDate
                      this.setState({ expireDate: date, expireDateStr: undefined });

                      // Trigger click on document root so that DatePicker automatically closes
                      document.documentElement.click();
                    } else {
                      this.setState({ expireDateStr: undefined });
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.target.blur();
                  }
                }}
                error={submitClicked && !expireDate}
                FormHelperTextProps={{ error: true }}
                helperText={submitClicked && !expireDate ? 'You must enter a valid Expiration Date' : ''}
              />
            </DatetimePickerTrigger>
          </Grid>
        )}
      </Grid>
    );
  };

  renderCustomerInsuranceItem = (insurance) => (
    <MenuItem value={insurance.id}>
      {insurance.carrier_name}
    </MenuItem>
  );

  renderCustomerInsurances = () => {
    const { classes, customerInsurance } = this.props;
    const {
      submitClicked,
      customerInsuranceValue,
      customerInsuranceFormValues,
      vehicleExpireDate,
      vehicleExpireDateStr,
    } = this.state;

    let expireDateError = '';
    if (!vehicleExpireDate) {
      expireDateError = 'You must enter a valid Expiration Date';
    } else if (vehicleExpireDate.isBefore(moment(), 'day')) {
      expireDateError = 'Insurance coverage is expired';
    }

    const isInsuranceError = submitClicked && (
      isEmpty(customerInsuranceFormValues.carrier_name) ||
      isEmpty(customerInsuranceFormValues.policy_number) ||
      !!expireDateError
    );

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography color={isInsuranceError ? 'secondary' : 'default'} type="headline" gutterBottom>
            <b>Insurance</b>
          </Typography>
          <Typography gutterBottom type="body1">
            Enter the coverage information for your selected vehicle below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            classes={{ select: classes.insuranceSelect }}
            onChange={this.handleCustomerInsuranceChange}
            value={customerInsuranceValue}
          >
            { customerInsurance.map((insurance) => this.renderCustomerInsuranceItem(insurance)) }
            <MenuItem value="NEW">New</MenuItem>
          </Select>
          <AutoInsuranceField
            fullWidth
            required
            margin="dense"
            value={customerInsuranceFormValues.carrier_name || ''}
            InputLabelProps={{ className: 'required', shrink: true }}
            onChange={(newValue) => this.setState({
              customerInsuranceFormValues: {
                ...customerInsuranceFormValues,
                carrier_name: newValue,
              },
            })}
            error={submitClicked && isEmpty(customerInsuranceFormValues.carrier_name)}
            FormHelperTextProps={{ error: true }}
            helperText={submitClicked && isEmpty(customerInsuranceFormValues.carrier_name) ? 'You must enter a valid Insurance Carrier' : ''}
          />
          <TextField
            margin="dense"
            fullWidth
            required
            label="Policy Number"
            value={customerInsuranceFormValues.policy_number || ''}
            InputLabelProps={{ className: 'required', shrink: true }}
            onChange={(e) => this.setState({
              customerInsuranceFormValues: {
                ...customerInsuranceFormValues,
                policy_number: e.target.value,
              },
            })}
            error={submitClicked && isEmpty(customerInsuranceFormValues.policy_number)}
            FormHelperTextProps={{ error: true }}
            helperText={submitClicked && isEmpty(customerInsuranceFormValues.policy_number) ? 'You must enter a valid Policy Number' : ''}
          />
          <DatetimePickerTrigger
            shortcuts={{
              '< Previous Year': moment.max(moment(), moment(vehicleExpireDate).subtract(1, 'years')),
              Clear: undefined,
              'Next Year >': moment(vehicleExpireDate).add(1, 'years'),
            }}
            showTimePicker={false}
            className={
              vehicleExpireDate
                ? 'popup-calendar'
                : 'popup-calendar not-selected'
            }
            onChange={(m) => this.setState({ vehicleExpireDate: m })}
            minDate={moment()}
            moment={vehicleExpireDate || moment()}
          >
            <TextField
              margin="dense"
              fullWidth
              required
              label="Expiration Date"
              value={vehicleExpireDateStr || (vehicleExpireDate && vehicleExpireDate.format('MM/DD/YYYY')) || ''}
              InputLabelProps={{ className: 'required', shrink: true }}
              onChange={(e) => this.setState({ vehicleExpireDateStr: e.target.value })}
              onBlur={() => {
                if (vehicleExpireDateStr) {
                  const date = moment(vehicleExpireDateStr);
                  if (date.isValid() && date.isSameOrAfter(moment())) {
                    // Set expireDate
                    this.setState({ vehicleExpireDate: date, vehicleExpireDateStr: undefined });

                    // Trigger click on document root so that DatePicker automatically closes
                    document.documentElement.click();
                  } else {
                    this.setState({ vehicleExpireDateStr: undefined });
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
              error={submitClicked && !!expireDateError}
              FormHelperTextProps={{ error: true }}
              helperText={submitClicked ? expireDateError : ''}
            />
          </DatetimePickerTrigger>
        </Grid>
      </Grid>
    );
  }

  renderLeaseModal = () => {
    const {
      classes,
      unit,
      vehicles,
    } = this.props;
    const isParking = unit.unit_type && unit.unit_type.startsWith('parking');

    const { selectedVehicleIndex } = this.state;

    const insuranceId = parseInt(this.state.insuranceValue, 10);
    const vehicleId = isParking && selectedVehicleIndex >= 0
      ? vehicles[selectedVehicleIndex].id : undefined;
    const isOpen = this.state.showLeaseModal && (!isParking || vehicleId);

    return (
      <Modal open={isOpen}>
        <Grid className={classes.modal}>
          <Hidden smUp>
            <Lease
              onAccept={this.completeMoveIn}
              onCancel={this.hideLeaseModal}
              style={{ height: '85vh', padding: 0 }}
              unitId={unit.id}
              insuranceId={Number.isNaN(insuranceId) ? undefined : insuranceId}
              vehicleId={vehicleId}
              zoom={0.5}
            />
          </Hidden>
          <Hidden xsDown>
            <Lease
              onAccept={this.completeMoveIn}
              onCancel={this.hideLeaseModal}
              style={{ height: '85vh', padding: 0 }}
              unitId={unit.id}
              insuranceId={Number.isNaN(insuranceId) ? undefined : insuranceId}
              vehicleId={vehicleId}
            />
          </Hidden>
        </Grid>
      </Modal>
    );
  };

  renderInsuranceModal = () => {
    const { classes } = this.props;

    return (
      <Modal
        open={this.state.showInsuranceModal}
        onClose={this.hideInsuranceModal}
      >
        <Grid className={classes.modal}>
          <div className="modal-pdf-container">
            <iframe title="Protection Plan" src={`${window.location.origin}/Protection_Plan_Brochure.pdf`} width="100%" height="100%" />
          </div>
          <Button className={classes.centerBottomButton} color="secondary" onClick={this.hideInsuranceModal} raised size="large">Close</Button>
        </Grid>
      </Modal>
    );
  };

  renderPaymentModal = () => {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.showPaymentModal}
      >
        <Grid className={classes.modal}>
          {/* Only allow Card payment when renting a new unit */}
          <NewPaymentForm
            onCancel={() => this.setState({ showPaymentModal: false })}
            paymentMethods={[{
              element: <Card />,
              label: 'Card',
              value: 'card',
            }]}
          />
        </Grid>
      </Modal>
    );
  };

  renderVehicleModal = () => {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.showVehicleModal}
      >
        <Grid className={classes.modal}>
          <VehicleForm
            isCreate
            onCancel={() => this.setState({ showVehicleModal: false })}
          />
        </Grid>
      </Modal>
    );
  };

  renderPaymentRow = (payment) => {
    const { classes } = this.props;
    const label = (
      <Typography type="body2">
        <CreditCardIcon className={classes.svg} />
        {payment.line}
      </Typography>
    );

    // Hide ACH payment options when renting a new unit
    return payment.profile_type === 'ach' ? null : (
      <FormControlLabel
        key={payment.id}
        value={`${payment.id}`}
        label={label}
        control={<Radio className={classes.radio} />}
      />
    );
  };

  renderPaymentMethods = () => {
    const {
      classes,
      facility: { payment_methods: paymentMethods },
      moveInCostError,
    } = this.props;

    const { promoCode } = this.state;

    const isError = this.state.submitClicked && this.state.paymentValue === 'not_selected';
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography color={isError ? 'secondary' : 'default'} type="headline" gutterBottom>
            <b>Payment</b>
          </Typography>
          <Typography type="body1">
            Select one of the payment options for your new unit below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={isError} fullWidth>
            <RadioGroup
              name="payment"
              className={classes.group}
              value={this.state.paymentValue}
              onChange={this.handlePaymentChange}
            >
              {paymentMethods && paymentMethods.map((payment) => this.renderPaymentRow(payment))}
            </RadioGroup>
            { isError && <FormHelperText error>You must select a payment method</FormHelperText> }
          </FormControl>
          <Grid className="new-payment-button">
            <span
              role="presentation"
              onClick={() => this.setState({ showPaymentModal: true })}
            >
              <AddCircleOutline /> Add Payment Method
            </span>
          </Grid>
        </Grid>
        <Grid className={classes.subItem} item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.autoPay}
                onChange={() => this.setState({ autoPay: !this.state.autoPay })}
              />
            }
            label={<Typography type="body1">Enroll in Autopay</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="baseline" spacing={8}>
            <Grid item>
              <TextField
                margin="dense"
                fullWidth
                className={classes.textField}
                label="Promotion Code"
                value={promoCode}
                onChange={(e) => this.setState({ promoCode: e.target.value })}
                error={!!moveInCostError}
                FormHelperTextProps={{ error: true }}
                helperText={moveInCostError || ''}
              />
            </Grid>
            <Grid item>
              <Button color="secondary" disabled={promoCode === this.state.promotion_code} onClick={this.applyPromotionCode} raised size="small">Apply</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderVehicleRow = (vehicle, index) => {
    const { classes } = this.props;
    const Icon = getVehicleIcon(vehicle.type);
    const label = (
      <Typography type="body2">
        <Icon className={classes.svg} />
        {getVehicleLabel(vehicle)}
      </Typography>
    );

    // Hide ACH payment options when renting a new unit
    return (
      <FormControlLabel
        key={vehicle.id || `v${index}`}
        value={index}
        label={label}
        control={<Radio className={classes.radio} />}
      />
    );
  };

  renderVehicles = () => {
    const { classes, vehicles } = this.props;

    const isError = this.state.submitClicked && this.state.selectedVehicleIndex < 0;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography color={isError ? 'secondary' : 'default'} type="headline" gutterBottom>
            <b>Vehicle</b>
          </Typography>
          <Typography type="body1">
            Select the vehicle that will (primarily) be parked in this spot.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={isError} fullWidth>
            <RadioGroup
              name="payment"
              className={classes.group}
              value={this.state.selectedVehicleIndex}
              onChange={this.handleVehicleChange}
            >
              {vehicles && vehicles.map((vehicle, index) => this.renderVehicleRow(vehicle, index))}
            </RadioGroup>
            { isError && <FormHelperText error>You must select a vehicle</FormHelperText> }
          </FormControl>
          <Grid className="new-payment-button">
            <span
              role="presentation"
              onClick={() => this.setState({ showVehicleModal: true })}
            >
              <AddCircleOutline /> Add Vehicle
            </span>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderPaymentSummaryRow = (cost, index) => {
    const rowStyles = {
      border: '1px solid #eee',
    };
    return (
      <tr key={index} style={rowStyles}>
        <td><Typography>{cost.description}:</Typography></td>
        <td><Typography>$</Typography></td>
        <td align="right"><Typography>{Number(cost.amount).toFixed(2)}</Typography></td>
      </tr>
    );
  };

  renderAdditionalInfo = () => {
    let info = null;
    if (this.props.unit.razorbacked) {
      info = 'This unit will already have a combination lock. This lock belongs to Red Dot Storage, please drop it off in the lock dropbox location at the front of the property. If you do not return the lock, you will be charged a lock fee of $12.00. The lock combination will be texted / emailed to you after the completion of the rental.';
    }

    return info && (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography type="headline" gutterBottom>
              <b>Additional Information</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="body1">
              {info}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderPaymentSummary = () => {
    const { moveInCosts } = this.props;
    if (moveInCosts === null) {
      return undefined;
    }
    const totalStyle = {
      borderBottom: '2px solid #eee',
    };
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography type="headline" gutterBottom>
            <b>Move-in Costs</b>
          </Typography>
        </Grid>
        <table>
          <tbody>
            {moveInCosts.charges.map(this.renderPaymentSummaryRow)}
            <tr style={totalStyle}>
              <td><Typography>Total:</Typography></td>
              <td><Typography>$</Typography></td>
              <td align="right">
                <Typography>
                  {Number(moveInCosts.charges.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.amount),
                    0.0,
                  )).toFixed(2)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    );
  };

  renderPaymentAuthorizationModal = () => {
    const { classes, paymentAuthorization } = this.props;
    if (!paymentAuthorization) return undefined;

    return (
      <Modal
        open={this.state.showPaymentAuthorizationModal}
        onClose={this.hidePaymentAuthorizationModal}
      >
        <Grid className={classes.modal}>
          <div className="modal-pdf-container">
            <iframe title="Terms & Conditions" src={this.state.paymentAuthorizationUrl} width="100%" height="100%" />
          </div>
          <Button className={classes.centerBottomButton} color="secondary" onClick={this.hidePaymentAuthorizationModal} raised size="large">Close</Button>
        </Grid>
      </Modal>
    );
  };

  renderPaymentAuthorization = () => {
    const { classes, paymentAuthorization } = this.props;
    if (!paymentAuthorization) return undefined;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography type="headline" gutterBottom>
            <b>Authorize Payment</b>
          </Typography>
        </Grid>
        { this.state.paymentAuthorizationUrl ? (
          <Grid item xs={12}>
            <Button
              className={classes.linkButton}
              onClick={this.showPaymentAuthorizationModal}
            >
              Terms & Conditions
            </Button>
          </Grid>
        ) : null }
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.paymentAuthorized}
                onChange={() => this.setState({ paymentAuthorized: !this.state.paymentAuthorized })}
              />
            }
            label={paymentAuthorization.message}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      back,
      classes,
      facility,
      history,
      moveIn,
      unit,
    } = this.props;
    if (!facility || !unit || moveIn) {
      return <Loading />;
    }

    const locationState = history.location.state || {};
    return (
      <Grid
        container
        spacing={24}
        className={classes.container}
        direction="row"
        alignItems="stretch"
      >
        {
          unit.unit_type && unit.unit_type.startsWith('parking') ? (
            <React.Fragment>
              <Grid item xs={12}>
                {this.renderVehicles()}
                {this.renderVehicleModal()}
              </Grid>
              <Grid item xs={12}>
                {this.renderCustomerInsurances()}
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              {this.renderTenantInsurances()}
              {this.renderInsuranceModal()}
            </Grid>
          )
        }
        <Grid item xs={12}>
          {this.renderPaymentMethods()}
          {this.renderPaymentModal()}
        </Grid>
        {this.renderAdditionalInfo()}
        <Grid item xs={12}>
          {this.renderPaymentSummary()}
          {this.renderLeaseModal()}
        </Grid>
        <Grid item xs={12}>
          {this.renderPaymentAuthorization()}
          {this.renderPaymentAuthorizationModal()}
        </Grid>
        <Grid item xs={12} className={classes.buttonGroup}>
          {
            !locationState.hideReservation && (
              <Button
                className={classes.button}
                color="secondary"
                onClick={() => history.push(back.url, back.state)}
                disabled={this.state.submitting}
              >
                Move-In Later
              </Button>
            )
          }
          <Button
            raised
            color="secondary"
            onClick={this.validateMoveIn}
            disabled={!this.state.paymentAuthorized || this.state.submitting}
          >
            Complete Move-In
          </Button>
        </Grid>
        <Modal open={this.state.submitting && !this.state.showLeaseModal}>
          <Loading />
        </Modal>
      </Grid>
    );
  }
}

MoveIn.defaultProps = {
  accountId: null,
  back: {
    url: '/',
  },
  facility: null,
  loggedIn: false,
  moveIn: null,
  moveInCosts: null,
  moveInCostError: null,
  moveInState: null,
  paymentAuthorization: null,
  title: 'Move-In',
  unit: null,
  vehicles: null,
  vehiclesLoaded: false,
};

const CustomerInsuranceShape = {
  id: PropTypes.string,
  account_id: PropTypes.string,
  carrier_name: PropTypes.string,
  policy_number: PropTypes.string,
  end_date: PropTypes.string,
  agent_name: PropTypes.string,
  agent_email: PropTypes.string,
  agent_phone: PropTypes.string,
};

MoveIn.propTypes = {
  accountId: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  back: PropTypes.shape({
    url: PropTypes.string.isRequired,
    state: PropTypes.object,
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  customerInsurance: PropTypes.arrayOf(PropTypes.shape(CustomerInsuranceShape)).isRequired,
  facility: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    default_address: PropTypes.object,
    email: PropTypes.string,
    insurances: PropTypes.arrayOf(PropTypes.shape({
      coverage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.number,
      name: PropTypes.string,
      premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    payment_methods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      line: PropTypes.string,
      profile_type: PropTypes.string,
    })),
    site_code: PropTypes.string,
    store_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    street_address: PropTypes.string,
  }),
  fetchingVehicles: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    push: PropTypes.func,
  }).isRequired,
  loggedIn: PropTypes.bool,
  moveIn: PropTypes.object,
  moveInCosts: PropTypes.object,
  moveInCostError: PropTypes.string,
  moveInState: PropTypes.object,
  paymentAuthorization: PropTypes.shape({
    message: PropTypes.string,
    file: PropTypes.string,
  }),
  title: PropTypes.string,
  unit: PropTypes.object,
  vehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
    license_plate: PropTypes.string,
    vin: PropTypes.string,
    color: PropTypes.string,
    owner_type: PropTypes.string,
    owner_id: PropTypes.string,
    lien_property_description: PropTypes.string,
    lienholder: PropTypes.string,
    leinholder_address: PropTypes.string,
    CustomerInsurances: PropTypes.arrayOf(PropTypes.shape(CustomerInsuranceShape)),
  })),
  vehiclesLoaded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  customerInsurance: result(getCustomerInsurances(state), 'toJS', []),
  facility: result(getFacility(state), 'toJS', null),
  fetchingVehicles: getFetching(state, 'vehicles'),
  loggedIn: getLoggedIn(state),
  moveIn: getMoveIn(state),
  moveInCosts: result(getMoveInCosts(state), 'toJS', null),
  moveInCostError: getMoveInCostError(state),
  moveInState: result(getMoveInState(state), 'toJS', null),
  paymentAuthorization: result(getPaymentAuthorizationMessage(state), 'toJS', null),
  unit: result(getUnit(state), 'toJS', null),
  vehicles: result(getAvailableVehicles(state), 'toJS', null),
  vehiclesLoaded: getLoaded(state, 'vehicles'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      appMsg,
      createPayment: createPaymentThunk,
      fetchMoveInCosts: fetchMoveInCostsThunk,
      fetchMoveInCostError,
      fetchPaymentAuthorizationMessage: fetchPaymentAuthorizationMessageThunk,
      fetchVehicles: fetchVehiclesThunk,
      moveIn: createMoveInThunk,
      saveMoveInState,
      transactSavedData: transactSavedDataThunk,
      setReturnUrl,
      createCustomerInsurance: createCustomerInsuranceThunk,
      updateCustomerInsurance: updateCustomerInsuranceThunk,
      assignCustomerInsurance: assignCustomerInsuranceThunk,
    },
    dispatch,
  ),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MoveIn));
