import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
} from 'material-ui';

import GeneralForm from '../../../components/Form';
import SelectField from '../../../components/Form/selectField';

import { getVehicles } from '../selectors';
import { updateVehicleOccupancyThunk } from '../reducer';
import { required } from '../../../utils/validations';
import { getVehicleLabel } from './vehicleForm';
import { routePropTypes } from '../../../utils/routes';

const ReduxForm = reduxForm({ form: 'vehicleOccupancyForm' })(GeneralForm);
const VehicleOccupancyForm = ({
  defaultIndex,
  history,
  location,
  unit,
  vehicles,
  ...formProps
}) => {
  const initialValues = useMemo(() => {
    const values = { occupancy_id: unit.occupancy_id };
    if (vehicles) {
      const vehicle = vehicles.find(v => ((v.get('occupancy_id') || '').toString() === unit.occupancy_id.toString()));
      if (vehicle) values.id = vehicle.get('id');
    }
    return values;
  }, [unit.occupancy_id, vehicles]);

  const defaultValue = useMemo(() => {
    // If Vehicles have not loaded, don't set default yet
    if (!vehicles) return undefined;
    // If there are no existing Vehicles, redirect to Vehicle Create Form
    else if (vehicles.size === 0) return 'create';

    if (typeof defaultIndex === 'number' && vehicles.size > defaultIndex) {
      // If new Vehicle was created, select it
      return vehicles.get(defaultIndex).get('id');
    } else if (initialValues.id) {
      // Otherwise, keep default selection
      return undefined;
    }

    // If there is no default selection, select the 1st element
    return vehicles.get(0).get('id');
  }, [initialValues.id, defaultIndex, vehicles]);

  const showVehicleCreate = useCallback(() => {
    history.replace(location.pathname, {
      unit_id: unit.id,
      showVehiclePicker: true,
      selectedIndex: vehicles.size,
    });
    history.push('/account/vehicles/create');
  }, [history, location.pathname, unit.id, vehicles]);

  const fields = useMemo(() => (
    [
      {
        custom: (
          <FormControl fullWidth margin="normal" key="id">
            <InputLabel className="required" htmlFor="id" required shrink>Parked Vehicle</InputLabel>
            <Field
              name="id"
              component={(props) => <SelectField overrideValue={defaultValue} {...props} />}
              onChange={(e, value) => {
                if (value === 'create') {
                  e.preventDefault();
                  showVehicleCreate();
                }
              }}
              validate={required}
            >
              { vehicles && vehicles.map((vehicle) => <MenuItem value={vehicle.get('id')}>{getVehicleLabel(vehicle.toJS())}</MenuItem>) }
              <MenuItem value="create">New</MenuItem>
            </Field>
          </FormControl>
        ),
      },
    ]
  ), [defaultValue, showVehicleCreate, vehicles]);

  return (
    <ReduxForm
      fields={fields}
      initialValues={initialValues}
      shrinkLabels
      {...formProps}
    />
  );
};
VehicleOccupancyForm.defaultProps = {
  defaultIndex: undefined,
  vehicles: undefined,
};
VehicleOccupancyForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  defaultIndex: PropTypes.number,
  unit: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    occupancy_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  vehicles: PropTypes.instanceOf(List),
  ...routePropTypes,
};


const mapStateToProps = (state) => ({
  vehicles: getVehicles(state),
});

// Only re-create bound methods if "ownProps.next" changes
const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    update: updateVehicleOccupancyThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleOccupancyForm));
