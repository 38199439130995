import React, { useEffect } from 'react';
import { Select } from 'redux-form-material-ui';
import { FormHelperText } from 'material-ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 10.5) + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  /* Material UI select has scrolling issue - we have a temporary fix here */
  onEnter: () => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 500);
  },
};
const renderSelect = ({
  meta: { touched, error },
  label,
  overrideValue,
  ...props
}) => {
  useEffect(() => {
    if (overrideValue) {
      props.input.onChange(overrideValue);
    }
  }, [overrideValue]);

  return (
    <div className="custom-select-wrapper">
      <Select
        {...props}
        error={Boolean(error && touched)}
        MenuProps={props.native ? undefined : MenuProps}
      />
      {(touched && error) && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

export default renderSelect;
