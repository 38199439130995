export default {
  background: '#F0F0F0',
  cardBackground: '#FFFFFF',
  listItemBackground: '#FFFFFF',
  lightBackground: '#f2f2f2',
  darkBackground: '#373a3c',
  red: '#F32735',
  darkRed: '#CD3326',
  lightRed: '#CD3326',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#0099FF',

  primary: '#F32735',
  secondary: '#FFFFFF',
  tertiary: '#0099FF',

  textPrimary: '#555555',
  textSecondary: '#888888',
  linkPrimary: '#0099FF',
  linkSecondary: '#F32735',

  border: '#D0D1D5',
  darkBorder: '#c2c2c2',

  tabbarBackground: '#EAEAEA',
  tabbarIconDefault: '#BABDC2',
  tabbarIconSelected: '#F32735',

  padding: 20,
  paddingSml: 10,
  paddingSS: 5,

  headline: 'rgba(0, 0, 0, 0.87)',
};
