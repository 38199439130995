import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  withStyles,
  Paper,
  Typography,
} from 'material-ui'
import {
  getOccupiedFacilities,
  getFacilityOccupiedUnits,
  getSelectedFacilityId,
  getSelectedFacility,
  getSelectedUnit,
} from '../MyUnits/selectors'

import {
  updateSelectedFacility,
  updateSelectedUnit,
  fetchBilling,
} from './reducer'
import { getLoaded, getLoading } from './selectors'

const styles = () => ({
  spaceAround: {
    justifyContent: 'space-around',
    padding: '2vh 2vw',
  },
  formControl: {
    width: 230,
    display: 'flex',
    maxWidth: '100%',
    flexBasis: '100%',
    marginBottom: '2vh',
  },
});

class UnitSelector extends React.Component {
  componentDidMount() {
    const {
      loaded,
      loading,
      actions,
    } = this.props;
    // This is called only for first billing load
    if (!loaded && !loading) {
      actions.fetchBilling();
    }
  }

  render() {
    const {
      classes,
      selectedFacility,
      selectedUnit,
      facilities,
      facilityUnits,
      actions,
    } = this.props;

    if (facilities.length === 0) {
      return (
        <Paper className={classes.spaceAround}>
          <Typography type="subheading" color="textSecondary">No Facility or Unit</Typography>
        </Paper>
      )
    }

    return (
      <Paper className={classes.spaceAround}>
        <Typography type="body1" color="textSecondary" paragraph>Select Facility and Unit</Typography>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="facility-id" shrink>Facility</InputLabel>
          <Select
            id="facility-id"
            value={selectedFacility.id}
            onChange={(e) => actions.updateSelectedFacility(e.target.value)}
          >
            {facilities.map(f => <MenuItem key={f.id} value={f.id}>{f.display_name}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="unit-id" shrink>Unit</InputLabel>
          <Select
            id="unit-id"
            value={selectedUnit ? selectedUnit.id : null}
            onChange={(e) => actions.updateSelectedUnit(e.target.value)}
          >
            {facilityUnits.map(u =>
              <MenuItem key={u.id} value={u.id}>Unit {u.unit_number}</MenuItem>)}
          </Select>
        </FormControl>
      </Paper>
    );
  }
}

const facilityType = {
  display_name: PropTypes.string,
  id: PropTypes.string,
};

const unitType = {
  id: PropTypes.string,
  moved_out: PropTypes.bool,
  system_status: PropTypes.string,
  unit_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UnitSelector.defaultProps = {
  facilities: [],
  facilityUnits: [],
  loaded: false,
  loading: false,
  selectedFacility: {},
  selectedUnit: null,
}

UnitSelector.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape(facilityType)),
  facilityUnits: PropTypes.arrayOf(PropTypes.shape(unitType)),
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  selectedFacility: PropTypes.shape(facilityType),
  selectedUnit: PropTypes.shape(unitType),
};

const mapStateToProps = (state) => {
  const fs = getOccupiedFacilities(state).toJS();
  return {
    facilities: fs,
    facilityUnits: fs.length > 0 &&
      getFacilityOccupiedUnits(state, getSelectedFacilityId(state)).toJS(),
    selectedFacility: fs.length > 0 && getSelectedFacility(state).toJS(),
    selectedUnit: fs.length > 0 && getSelectedUnit(state) && getSelectedUnit(state).toJS(),
    loaded: getLoaded(state),
    loading: getLoading(state),
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateSelectedFacility,
    updateSelectedUnit,
    fetchBilling,
  }, dispatch),
});

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitSelector));
