/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { Link } from 'react-router-dom';
import Title from '../../components/Title';
import ContactForm from './acquisitionContactForm';

const styles = (/* theme */) => ({
  bodyContent: {
    fontSize: '16px',
    padding: '0.5em 0',
  },
  footerContent: {
    fontSize: '19px',
    paddingBottom: '0.5em',
  },
  pageContainer: {
    gap: '1em',
  },
  privacyLink: {
    color: 'black',
    textDecoration: 'underline',
  },
  textContainer: {
    'text-align': 'center',
  },
});

const ContactUs = ({ classes, phone, title }) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid className={classes.pageContainer} container justify="center">
      <Title>
        <Typography type="display4">STORAGE FACILITY DEVELOPMENT &</Typography>
        <Typography type="display2">ACQUISITIONS</Typography>
      </Title>
      <Grid container justify="center">
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8} xl={8}>
          <Typography className={classes.bodyContent} type="body1">Are you considering selling your storage facility?</Typography>
          <Typography className={classes.bodyContent} type="body1">Red Dot Storage is one of the nation’s largest self storage owner/operators. As a well-capitalized buyer, we are always looking for new storage investment opportunities. Our financing is already in place to purchase your property and we offer a quick close (average of 60 days), preparation of all closing documents, and zero brokerage fees or commissions.</Typography>
          <Typography className={classes.bodyContent} type="body1">As a direct buyer, Red Dot has been able to acquire 200 <Link className="red" to="/selectFacility">self storage facilities</Link> at a rapid pace. Red Dot Storage boasts a quick, simple, and effortless transaction experience for the seller.</Typography>
          <Typography className={classes.bodyContent} type="body1">If you are interested in selling your property please fill out the form below or call <a className="link" href={`tel:${phone}`}>{phone}</a> and one of our acquisition specialists will be happy to assist you!</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
        <ContactForm />
      </Grid>
      <Grid container justify="center">
        <Grid className={classes.textContainer} item xs={12} sm={11} md={10} lg={8} xl={8}>
          <Typography className={classes.footerContent} type="body1">To learn more about how we use the information you send us, please see our <Link className={classes.privacyLink} to="/privacy-policy">privacy policy</Link>.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContactUs.defaultProps = {
  phone: '(720) 799-8976',
  title: 'Sell Your Facility',
}

ContactUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  phone: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(ContactUs);
