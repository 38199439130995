import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { List } from 'immutable';
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Modal,
  Paper,
  Typography,
  withStyles,
} from 'material-ui';
import { Directions, AttachMoney, Description, EventBusy, ExitToApp, Shop } from 'material-ui-icons';
import moment from 'moment';

import Unit from '../../../components/Unit';
import SelectDate from '../../../components/SelectDate';
import { updateSelectedFacilityUnit } from '../../../containers/Billing/reducer';
import RootRef from '../../../components/RootRef';
import { cancelMoveOut, moveOut } from '../reducer';
import { getUpdating } from '../selectors';
import Colors from '../../../styles/colors';
import './styles.css';
import { routePropTypes } from '../../../utils/routes';
import { getVehicles } from '../../Account/selectors';
import { getVehicleLabel } from '../../Account/RegisterVehicle/vehicleForm';
import VehicleOccupancyForm from '../../Account/RegisterVehicle/occupancyForm';

const styles = (theme) => ({
  addressContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  addressIcon: {
    width: '20px',
    height: '20px',
  },
  addressLink: {
    display: 'inline',
    fontWeight: 500,
    verticalAlign: 'text-bottom',
    whiteSpace: 'nowrap',
  },
  commandLink: {
    display: 'inline-block',
    paddingRight: '0.375em',
  },
  completeMoveInButton: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 36,
      left: 400,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
  facilityContainer: {
    padding: 40,
    position: 'relative',
  },
  facilityTextItem: {
    padding: '8px 0px !important',
  },
  firstLine: {
    [theme.breakpoints.down(800)]: {
      width: '100%',
    },
  },
  gateCode: {
    fontWeight: 'normal',
  },
  headlineLink: {
    color: Colors.headline,
  },
  homeButton: {
    backgroundColor: Colors.white,
    width: 40,
    height: 40,
    margin: 10,
    borderRadius: 2,
    boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
    '&:hover': {
      color: Colors.black,
    },
  },
  inlineText: {
    display: 'inline',
  },
  modal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px`,
    },
  },
  modalButtons: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  modalGrid: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  modalLarge: {
    maxHeight: '90%',
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '90%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '66%',
    },
  },
  modalSmall: {
    maxHeight: 600,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 100,
    },
  },
  modalUnit: {
    backgroundColor: Colors.background,
    padding: 12,
  },
  root: {
    margin: '20px 0 20px 0',
  },
  unitGrid: {
    [theme.breakpoints.down('xs')]: {
      gap: '8px',
    },
    [theme.breakpoints.up('sm')]: {
      gap: '12px',
    },
  },
  unitContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(50% - 6px)',
      width: '265px',
    },
    minWidth: '195px',
    minHeight: '168px',
    boxShadow: '2px 2px 6px 0px darkgrey',
  },
});

class Facility extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultVehicleIndex: undefined,
      filteredUnits: [],
      modalHeader: '',
      scrollToUnit: undefined,
      showDatePicker: false,
      showModal: false,
      showVehiclePicker: false,
    };

    this.cards = {};

    if (props.location.state && props.location.state.unit_id) {
      this.state.filteredUnits = props.facility.units
        .filter(u => u.id === props.location.state.unit_id);

      if (this.state.filteredUnits.length > 0) {
        this.state.showVehiclePicker = !!props.location.state.showVehiclePicker
          && props.vehicles && props.vehicles.size > 0;
        this.state.defaultVehicleIndex = props.location.state.selectedIndex;
      }
    }
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.unit_id) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ scrollToUnit: this.props.location.state.unit_id });
    }
  }

  shouldComponentUpdate(props) {
    // If move-out / cancel move-out completes successfully, hide modal
    let shouldUpdate = true;
    if (props.updating !== this.props.updating && !props.updating && !props.error) {
      this.hideModal();
      shouldUpdate = false;
    }
    return shouldUpdate;
  }

  componentDidUpdate() {
    if (this.state.scrollToUnit) {
      // Scroll to desired Unit
      const card = this.cards[this.state.scrollToUnit];
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.scrollToUnit = undefined;

      if (card) {
        card.scrollIntoView();
        this.props.history.replace(this.props.location.pathname);
      }
    }
  }

  getButtons = (unit) => {
    const buttons = {};
    if (unit.system_status === 'reserved') {
      buttons.moveIn = (
        <Link to={`/reservation/${unit.id}/moveIn`}>
          <Button raised color="secondary">Complete Move-In</Button>
        </Link>
      );
    }
    return buttons;
  }

  showDatePicker = (unit) => {
    const state = { showDatePicker: true };
    if (this.state.filteredUnits.length <= 0 && unit) {
      state.filteredUnits = [unit];
    }
    this.setState(state);
  }

  showVehiclePicker = (unit) => {
    const state = { showVehiclePicker: true };
    if (this.state.filteredUnits.length <= 0 && unit) {
      state.filteredUnits = [unit];
    }
    this.setState(state);
  }

  hideModal = () => {
    this.setState({
      showDatePicker: false,
      showModal: false,
      showVehiclePicker: false,
      defaultVehicleIndex: undefined,
      filteredUnits: [],
    });
  }

  unitList = (units) => {
    const {
      actions,
      classes,
      facility,
      history,
      vehicles,
    } = this.props;

    const self = this;

    return units.map((item) => {
      const commands = item.system_status === 'reserved' ? [{
        id: 'moveIn',
        icon: <Shop />,
        label: 'Move In',
        callback: (unit) => history.push(`/reservation/${unit.id}/moveIn`),
      }] : [{
        id: 'billing',
        icon: <AttachMoney />,
        label: 'Pay Bill',
        callback: (unit) => {
          actions.updateSelectedFacilityUnit({ facility_id: facility.id, unit_id: unit.id });
          history.push('/billing');
        },
      }, {
        id: 'moveOut',
        icon: item.move_out_date ? <EventBusy /> : <ExitToApp />,
        label: item.move_out_date ? 'Cancel Move-Out' : 'Move Out',
        callback: (unit) => (unit.move_out_date
          ? actions.cancelMoveOut({ unit_id: unit.id })
          : this.showDatePicker(unit)),
      }, {
        id: 'viewLease',
        icon: <Description />,
        label: 'View Lease',
        callback: (unit) => history.push(`/lease/${unit.lease_envelope_id}`),
      }];

      const isParking = item.unit_type && item.unit_type.startsWith('parking');
      const vehicle = item.occupancy_id && vehicles && vehicles.find(v => (v.get('occupancy_id') || '').toString() === item.occupancy_id.toString());
      return (
        <RootRef
          rootRef={(domNode) => {
            self.cards[item.id] = domNode;
          }}
        >
          <Grid
            item
            key={item.unit_number}
            className={classes.unitContainer}
          >
            <Unit commands={commands} facilityId={this.props.facility.id} unit={item}>
              {
                item.move_out_date &&
                <Grid item>
                  <Typography className={classes.commandLink} type="body2">Move-Out Date: </Typography><Typography type="caption" color="secondary" component="a" className="link" onClick={() => this.showDatePicker(item)}>{moment(item.move_out_date, moment.HTML5_FMT.DATE).format('l')}</Typography>
                </Grid>
              }
              {
                isParking &&
                <Grid item>
                  <Typography className={classes.commandLink} type="body2">Vehicle: </Typography><Typography type="caption" color="secondary" component="a" className="link" onClick={() => this.showVehiclePicker(item)}>{vehicle ? getVehicleLabel(vehicle.toJS()) : 'Add'}</Typography>
                </Grid>
              }
            </Unit>
          </Grid>
        </RootRef>
      );
    });
  }

  countReservedUnit = () => {
    const {
      facility: {
        units,
      },
    } = this.props;
    return filter(units, { system_status: 'reserved' }).length;
  }

  handleCompleteMoveIn = () => {
    const {
      facility: {
        units,
      },
    } = this.props;
    const reservedUnits = filter(units, { system_status: 'reserved' });
    if (reservedUnits.length === 1) {
      this.props.history.push(`/reservation/${reservedUnits[0].id}/moveIn`);
    } else {
      this.setState({
        filteredUnits: reservedUnits,
        modalHeader: 'Which rental would you like to complete?',
        showModal: true,
      });
    }
  }

  renderUnitForModal = (unit) => {
    const { classes } = this.props;
    const buttons = this.getButtons(unit);
    return (
      <Grid item xs={12} key={unit.id}>
        <Grid alignItems="center" container className={classes.modalUnit}>
          <Grid item sm={4} md={3} xs={12}>
            <Unit className={classes.modalGrid} unit={unit} />
          </Grid>
          <Grid item sm xs={12}>
            <Grid alignItems="center" container className={classes.modalButtons}>
              {
                Object.keys(buttons).map((key) => (
                  <Grid item key={key}>
                    {buttons[key]}
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderUnitsModal = () => {
    const {
      classes,
      facility: {
        display_name: facilityName,
        full_address: facilityAddress,
      },
    } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.showModal && this.state.filteredUnits.length > 0}
        onClose={this.hideModal}
      >
        <Grid className={`${classes.modal} ${classes.modalSmall}`}>
          <Typography type="headline" paragraph>{this.state.modalHeader}</Typography>
          <Typography type="subheading">{facilityName}</Typography>
          <Typography color="textSecondary" paragraph>{facilityAddress}</Typography>
          <Grid container spacing={24} alignItems="center" justify="center">
            {this.state.filteredUnits.map(this.renderUnitForModal)}
          </Grid>
        </Grid>
      </Modal>
    )
  }

  renderVehicleModal = () => {
    const { classes } = this.props;
    const { defaultVehicleIndex, filteredUnits } = this.state;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={filteredUnits.length > 0 && this.state.showVehiclePicker}
      >
        <Grid className={`${classes.modal} ${classes.modalSmall}`}>
          <VehicleOccupancyForm
            defaultIndex={defaultVehicleIndex}
            title={`Unit ${filteredUnits[0].unit_number}`}
            unit={filteredUnits[0]}
            onCancel={this.hideModal}
            onSubmitComplete={this.hideModal}
          />
        </Grid>
      </Modal>
    );
  }

  renderDatePickerModal = () => {
    const {
      classes,
      facility,
    } = this.props;

    const unit = this.state.filteredUnits.length > 0 ? this.state.filteredUnits[0] : null;
    const title = (
      <Grid style={{ marginBottom: '0.35em' }}>
        <Typography className={classes.inlineText} type="title" color="textSecondary">
          {`${facility.default_address.city} Store #${facility.store_number}: `}
        </Typography>
        <Typography className={classes.inlineText} type="title" color="textSecondary" noWrap>
          {`Unit ${unit.unit_number}`}
        </Typography>
      </Grid>
    );

    const moveOutDate = unit && unit.move_out_date
      ? moment(unit.move_out_date, moment.HTML5_FMT.DATE) : undefined;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.showDatePicker}
        onClose={this.hideModal}
      >
        <Grid className={`${classes.modal} ${classes.modalLarge}`}>
          <SelectDate
            acceptButton={{
              text: 'Confirm',
              onClick: (date) => {
                if (unit && date && (!moveOutDate || !moveOutDate.isSame(date, 'day'))) {
                  this.props.actions.moveOut({
                    unit_id: unit.id,
                    date: date.format('YYYY-MM-DD'),
                  });
                }
                this.hideModal();
              },
            }}
            bodyText="Please click on the date that you would like to move out, then press the &quot;Confirm&quot; button below."
            defaultValue={moveOutDate}
            secondaryButton={{
              text: 'Cancel',
              color: 'default',
              onClick: this.hideModal,
            }}
            selectedBodyText="Please click the &quot;Confirm&quot; button below to confirm your move-out."
            headerText="Move-Out Date"
            maxSelectableDays={30}
            title={title}
          />
        </Grid>
      </Modal>
    )
  }

  render() {
    const { facility, classes } = this.props;
    const add = facility.default_address;
    const cityStateZip = add ? `${add.city}, ${add.state} ${add.zip}` : '';
    const streetAddress = cityStateZip ? `${add.street}, ` : facility.street_address;
    const fullAddress = `${streetAddress}${cityStateZip}`;
    const unitsList = facility.units.filter((u) => !u.moved_out);

    if (unitsList.length === 0) {
      return false;
    }
    const units = this.unitList(unitsList);

    let modal;
    if (this.state.showDatePicker) {
      modal = this.renderDatePickerModal();
    } else if (this.state.showVehiclePicker) {
      modal = this.renderVehicleModal();
    } else {
      modal = this.renderUnitsModal();
    }

    return (
      <div>
        <Paper className={classes.facilityContainer} elevation={4}>
          <Grid
            container
            justify="space-between"
            alignItems="baseline"
          >
            <Grid item className={`${classes.firstLine} ${classes.facilityTextItem}`}>
              <Link className={`link ${classes.headlineLink}`} to={`/facilities/${facility.id}`}>
                <Typography type="headline">{facility.display_name}</Typography>
              </Link>
            </Grid>
            <Grid item className={classes.facilityTextItem}>
              <Typography className={classes.gateCode} type="display4">Gate Code:</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="baseline"
            wrap="wrap-reverse"
          >
            <Grid item className={`${classes.firstLine} ${classes.facilityTextItem}`}>
              <a className="link" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(fullAddress)}`} rel="noreferrer" target="_blank" title="Get Directions">
                <Grid className={classes.addressContainer}>
                  <Directions className={classes.addressIcon} />
                  <Typography className={classes.addressLink} color="secondary" type="body1">{streetAddress}<wbr />{cityStateZip}</Typography>
                </Grid>
              </a>
            </Grid>
            <Grid item className={classes.facilityTextItem}>
              <Typography type="body1">{facility.gate_code || 'Not available'}</Typography>
            </Grid>
          </Grid>
          {
            facility.phone_number &&
            <Grid container>
              <Grid item className={classes.facilityTextItem}>
                <Typography type="body1"><a className="link" href={`tel:${facility.phone_number}`}>{facility.phone_number}</a></Typography>
              </Grid>
            </Grid>
          }
          <Grid container>
            <Grid item className={classes.facilityTextItem}>
              <Typography type="body1"><a className="link" href={`mailto:${facility.email}`} rel="noreferrer" target="_blank">{facility.email}</a></Typography>
            </Grid>
          </Grid>
          {
            this.countReservedUnit() > 0 &&
            <Button
              raised
              color="secondary"
              className={classes.completeMoveInButton}
              onClick={this.handleCompleteMoveIn}
            >
              Complete Move-In
            </Button>
          }
          <Divider light classes={{ root: classes.root }} />
          <Hidden smUp>
            <Grid className={classes.unitGrid} container spacing={24}>
              {units}
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid className={classes.unitGrid} container spacing={40}>
              {units}
            </Grid>
          </Hidden>
          <Divider light classes={{ root: classes.root }} />
          <Link to={`/facilities/${facility.id}`}>
            <Button color="secondary" fullWidth raised>New Unit</Button>
          </Link>
        </Paper>
        { modal }
      </div>
    );
  }
}

Facility.defaultProps = {
  error: null,
  updating: false,
  vehicles: undefined,
};

Facility.propTypes = {
  actions: PropTypes.shape({
    cancelMoveOut: PropTypes.func,
    moveOut: PropTypes.func,
    updateSelectedFacilityUnit: PropTypes.func,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  facility: PropTypes.object.isRequired,
  updating: PropTypes.bool,
  vehicles: PropTypes.instanceOf(List),
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  updating: getUpdating(state),
  vehicles: getVehicles(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    cancelMoveOut,
    moveOut,
    updateSelectedFacilityUnit,
  }, dispatch),
});

const facilityContainer = connect(mapStateToProps, mapDispatchToProps)(Facility);
export default withRouter(withStyles(styles)(facilityContainer));
