export function email(value) {
  return (value && !/^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? 'Invalid email'
    : undefined)
}

export function required(value) {
  return (!value && typeof value !== 'boolean' && typeof value !== 'number') ? 'Required' : undefined;
}

export function zipCode(value) {
  return (value && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ? 'Invalid Zip Code'
    : undefined)
}

export function year(value) {
  return /^\d{4}$/.test(value) ? undefined : 'Invalid Year';
}

export function militaryId(value) {
  return /^\d{10}$/.test(value) ? undefined : 'Invalid ID';
}

export function vin(value) {
  return !value || value.length < 11 || value.length > 17 ? 'Invalid VIN' : undefined;
}

export function taxId(value) {
  return /^\d{9}(\d{2})?$/.test(value) ? undefined : 'Invalid Tax ID';
}

export function futureYear(value) {
  const invalidYear = year(value);
  return invalidYear || (value < new Date().getFullYear() ? 'Expired' : undefined);
}

export function futureDate(value) {
  if (value) {
    const inputDate = new Date(value);
    if (Number.isNaN(inputDate.getTime())) return 'Invalid Date';
    // Add 1 day so we can ignore time difference
    inputDate.setDate(inputDate.getDate() + 1);
    if (inputDate < new Date()) return 'Date must be in the future';
  }
  return undefined;
}

export function minLength(length, errorMsg, regex) {
  return (value) => {
    const val = value && regex ? value.replace(regex, '') : value;
    return val && val.length < length ? errorMsg || `Field must have at least ${length} characters` : undefined;
  };
}

export function numeric(min, max) {
  return (value) => {
    if (!value) return undefined;

    const startIndex = Math.max(0, value.search(/[^0]/));
    const sanitizedValue = value.substr(startIndex);
    const numValue = parseFloat(sanitizedValue);
    let errorMsg = 'Must be a number';
    if (min) {
      if (max) {
        errorMsg += ` between ${min} and ${max}`;
      } else {
        errorMsg += ` no less than ${min}`;
      }
    } else if (max) {
      errorMsg += ` no greater than ${max}`;
    }

    return !Number.isNaN(numValue)
      && numValue.toString().length === sanitizedValue.length
      && (typeof min !== 'number' || numValue >= min)
      && (typeof max !== 'number' || numValue <= max)
      ? undefined : errorMsg
  }
}

export function equalsField(fieldName, labelName) {
  return (value, allValues) => {
    if (!fieldName) return undefined;
    const fieldTitle = labelName || fieldName.split('_').map((word) => `${word.charAt(0).toUpperCase()}${word.substr(1)}`).join(' ');
    return !fieldName || value === allValues[fieldName] ? undefined : `Must match the value provided for ${fieldTitle}`;
  };
}
