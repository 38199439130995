import React from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GeneralForm from '../../../../components/Form';
import PaymentFormTemplate from '../../../Billing/PaymentMethods/PaymentForm/template';
import { createPaymentThunk } from '../../reducer';
import { getUserInfo } from '../../../Auth/selectors';

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo(state).toJS();
  const address = userInfo && userInfo.addresses && userInfo.addresses.length > 0
    ? (userInfo.addresses.find((addr) => addr.default) || userInfo.addresses[0])
    : {};
  return {
    template: <PaymentFormTemplate {...ownProps} />,
    disablePristine: true,
    isCreate: true,
    initialValues: {
      profile_type: 'card',
      bankData: { accountType: 'checking' },
      cardData: {
        addressAttributes: {
          country: address.country,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          street_address: address.street,
          city: address.city,
          state: address.state,
          zip: address.zip,
        },
      },
    },
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators({
    create: createPaymentThunk,
    cancel: ownProps.onCancel,
  }, dispatch),
});

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'paymentForm',
})(GeneralForm)));
