import React from 'react';
import { Field, FormSection } from 'redux-form';
import { TextField, Select } from 'redux-form-material-ui';
import { UsaStates } from 'usa-states';
import {
  Hidden,
  FormControl,
  Typography,
  InputLabel,
  Grid,
} from 'material-ui';
import MaskedTextField from '../../../../components/Form/maskedTextField';
import {
  cardNumberMask,
  cardMonthMask,
  cardYearMask,
  cardCcvMask,
  zipMask,
} from '../../../../constants';
import {
  required as requiredValidation,
  zipCode as zipCodeValidation,
  futureYear as yearValidation,
  numeric as numericValidation,
  minLength as minLengthValidation,
} from '../../../../utils/validations';

const { states } = new UsaStates();
const monthValidation = [requiredValidation, numericValidation(1, 12), minLengthValidation(2, 'Must contain 2 digits')];

const Card = () => (
  <div>
    <FormSection name="cardData">
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <Field
            fullWidth
            name="cardNumber"
            component={MaskedTextField}
            label="Card Number"
            required
            type="text"
            InputLabelProps={{ className: 'required', shrink: true }}
            props={{ mask: cardNumberMask }}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Field
            fullWidth
            name="cardCode"
            component={MaskedTextField}
            label="CCV"
            required
            type="text"
            InputLabelProps={{ className: 'required', shrink: true }}
            props={{ mask: cardCcvMask }}
            validate={requiredValidation}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Field
            fullWidth
            name="month"
            component={MaskedTextField}
            label="Month"
            required
            type="text"
            placeholder="MM"
            InputLabelProps={{ className: 'required', shrink: true }}
            props={{ mask: cardMonthMask, style: { textAlign: 'center' } }}
            validate={monthValidation}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Field
            fullWidth
            name="year"
            component={MaskedTextField}
            label="Year"
            required
            type="text"
            placeholder="YY"
            normalize={(value, prevValue) => (value.length === 2 && prevValue.length < 2 ? `20${value}` : value)}
            InputLabelProps={{ className: 'required', shrink: true }}
            props={{ mask: cardYearMask, style: { textAlign: 'center' } }}
            validate={[requiredValidation, yearValidation]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              color: '#555',
              fontStyle: 'italic',
              textAlign: 'center',
            }}
          >
            If your credit card billing information has changed, please update it below.
          </Typography>
        </Grid>
        <FormSection name="addressAttributes">
          <Grid container>
            <Grid item md={6} xs={12}>
              <Field
                fullWidth
                name="first_name"
                component={TextField}
                label="First Name"
                margin="normal"
                required
                type="text"
                InputLabelProps={{ className: 'required', shrink: true }}
                validate={requiredValidation}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                fullWidth
                name="last_name"
                component={TextField}
                label="Last Name"
                margin="normal"
                required
                type="text"
                InputLabelProps={{ className: 'required', shrink: true }}
                validate={requiredValidation}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Field
                fullWidth
                name="street_address"
                component={TextField}
                label="Address"
                margin="normal"
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                fullWidth
                name="city"
                component={TextField}
                label="City"
                margin="normal"
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                fullWidth
                margin="normal"
                key="state"
              >
                <InputLabel htmlFor="state" shrink>State</InputLabel>
                <Field
                  name="state"
                  component={Select}
                  native
                >
                  <option />
                  {
                    states.map(item => (
                      <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
                    ))
                  }
                </Field>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                fullWidth
                name="zip"
                component={MaskedTextField}
                label="Zip Code"
                margin="normal"
                type="text"
                InputLabelProps={{ shrink: true }}
                props={{ mask: zipMask }}
                validate={zipCodeValidation}
              />
            </Grid>
            <Hidden xsUp>
              <Field
                name="country"
                component={TextField}
                margin="normal"
                type="hidden"
                InputLabelProps={{ shrink: true }}
              />
            </Hidden>
          </Grid>
        </FormSection>
      </Grid>
    </FormSection>
  </div>
);

export default Card
