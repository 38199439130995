import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import MapSearch from '../../components/MapSearch';
import { routePropTypes } from '../../utils/routes';
import PhotoCard from '../../components/PhotoCard';
import backgroundImage from '../../images/home/storage_center.png';
import kioskPhoto from '../../images/photos/kiosk.jpg';
import movingPhoto from '../../images/photos/movingCouple.jpg';
import phonePhoto from '../../images/photos/phone.jpg';
import facilityInteriorPhoto from '../../images/photos/indoorStorageUnits.jpg';
import keypadPhoto from '../../images/photos/securityKeypad.jpg';
import AppLogo from '../../components/Logo';

const styles = (/* theme */) => ({
  fullWidth: {
    width: '100%',
  },
  pageContainer: {
    gap: '1em',
    paddingBottom: '1em',
    width: 'calc(100% + 8px)',
  },
  pageTitle: {
    fontSize: '50px',

    '@media screen and (max-width: 979px)': {
      fontSize: '30px',
    },
  },
  photo: {
    minHeight: '300px',
    objectFit: 'cover',
    width: '100%',
  },
  photoContainer: {
    position: 'relative',
    height: 'max-content',
  },
  photoFilter: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#ffffff',
    width: '100%',
    height: '100%',
    opacity: 0.65,
    zIndex: 1,
  },
  photoContent: {
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
  },
});

const Home = ({
  classes,
  history,
  photoCards,
  title,
}) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const searchAddress = useCallback((searchValue) => {
    history.push(`/selectFacility?sValue=${searchValue}`);
  }, []);

  const searchLocation = useCallback(() => {
    history.push('/selectFacility?geocode=true');
  }, []);

  return (
    <Grid className={classes.pageContainer} container justify="center">
      <Grid className={classes.photoContainer} item xs={12}>
        <div className={classes.photoFilter} />
        <img alt="Storage Facility Interior" className={classes.photo} src={backgroundImage} />
        <Grid alignItems="center" className={classes.photoContent} container direction="column" justify="space-around">
          <AppLogo size="large" />
          <Typography className={classes.pageTitle} type="display2">SAFE. SIMPLE. STORAGE.</Typography>
          <MapSearch
            className={classes.fullWidth}
            onGeocode={searchAddress}
            onLocate={searchLocation}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="photo-cards-mosaic">
          <div className="photo-cards-mosaic-container">
            <div className="photo-cards-container">
              {photoCards.map((props) => <PhotoCard {...props} />)}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

Home.defaultProps = {
  title: 'Home',
  photoCards: [
    {
      alt: 'Couple moving a box',
      backgroundImage: movingPhoto,
      key: 'hours',
      paragraphs: [
        'Get in, get what you need, and get out and on your way with convenient self storage open 24/7, 365 days a year.',
      ],
      title: (
        <React.Fragment>
          24/7/365<br />Self Storage
        </React.Fragment>
      ),
    },
    {
      alt: 'Indoor Storage Units',
      backgroundImage: facilityInteriorPhoto,
      key: 'deposit',
      paragraphs: [
        'You pay rent - that\'s it. Simple self storage is our guarantee.',
      ],
      title: 'No Security Deposit',
    },
    {
      alt: 'A scurity keypad at the gate',
      backgroundImage: keypadPhoto,
      key: 'security',
      paragraphs: [
        'Next generation technology, fully fenced perimeters, and gated entries deliver worry-free self storage to our customers.',
      ],
      title: 'Safe & Secure',
    },
    {
      alt: 'A customer on her phone',
      backgroundImage: phonePhoto,
      key: 'contact',
      paragraphs: [
        'Our knowledgeable and personable storage experts are available 7 days a week.',
      ],
      title: 'Talk to Us',
    },
    {
      alt: 'Rental Kiosk',
      backgroundImage: kioskPhoto,
      key: 'rental',
      paragraphs: [
        'Rent onsite at a virtual kiosk, online via an easy-to-navigate website, or by talking with a storage expert.',
      ],
      title: (
        <React.Fragment>
          Rent on<br />Your Time
        </React.Fragment>
      ),
    },
  ],
};
Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  photoCards: PropTypes.arrayOf(PropTypes.shape(PhotoCard.propTypes)),
  title: PropTypes.string,
  ...routePropTypes,
};

export default withRouter(withStyles(styles)(Home));
