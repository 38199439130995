/* eslint-disable react/no-find-dom-node */
import React from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class RootRef extends React.Component {
  componentDidMount() {
    this.props.rootRef(ReactDOM.findDOMNode(this));
  }

  componentWillUnmount() {
    this.props.rootRef(null);
  }

  render() {
    return this.props.children;
  }
}
RootRef.propTypes = {
  rootRef: PropTypes.func.isRequired,
};
