import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  withStyles,
} from 'material-ui';
import { Link } from 'react-router-dom';
import { generateLease, signLeaseThunk } from './reducer';
import { getError, getLease, getLoaded } from './selectors';
import Loading from '../../components/Loading';
import { routePropTypes } from '../../utils/routes';
import './styles.css';

const styles = () => ({
  pageContainer: {
    paddingBottom: '10px',
  },
  pdfContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    maxWidth: '1200px',
  },
});

class SignLease extends Component {
  constructor(props) {
    super(props);

    const qParams = new URLSearchParams(this.props.location.search);
    this.state = {
      account_id: qParams.get('account_id'),
      occupancy_id: qParams.get('occupancy_id'),
    };
  }

  componentDidMount() {
    document.title = this.props.title;

    // Generate Lease
    this.props.actions.generateLease({
      account_id: this.state.account_id,
      occupancy_id: this.state.occupancy_id,
      redirect: true,
    });

    // Fire callback when user signs the lease
    window.onLeaseAccepted = async () => {
      window.gtag('event', 'sign_lease', {
        occupancy_id: this.state.occupancy_id,
        items: [{
          item_id: this.props.lease.get('id'),
          item_category: 'Lease',
        }],
      });

      this.props.actions.signLease({
        lease_envelope_id: parseInt(this.props.lease.get('id'), 10),
        occupancy_id: this.state.occupancy_id,
      });
    };
  }

  componentWillUnmount() {
    // Remove callback
    delete window.onLeaseAccepted;
  }

  /* eslint-disable react/no-danger */
  renderLeasePDF() {
    const {
      classes,
      error,
      lease,
      loaded,
    } = this.props;

    let leaseContent;
    const leaseStatus = lease && lease.get('status');
    const contractDetails = lease && lease.get('contract_details');
    const contractStatus = contractDetails && contractDetails.get('status');

    if (!loaded && !error) {
      leaseContent = <Loading />;
    } else if (error) {
      leaseContent = (
        <React.Fragment>
          <Typography align="center" type="display1" gutterBottom>Link expired.</Typography>
          <Typography align="center" type="display1">Please <Link className="link" to="/">Login</Link> or contact Customer Service at <a className="link" href="tel:9705106860">970-510-6860</a> for assistance.</Typography>
        </React.Fragment>
      );
    } else if (leaseStatus === 2 || contractStatus === 'signed') {
      leaseContent = (
        <React.Fragment>
          <Typography align="center" type="display1" gutterBottom>Congratulations, you&apos;re moved in!</Typography>
          <Typography align="center" type="display4" style={{ fontWeight: 'bold' }}>Your gate code will be texted / emailed to you in a few moments, or you can <Link className="link" to="/">Login</Link> to view it now.</Typography>
        </React.Fragment>
      );
    } else {
      const url = lease && lease.getIn(['contract_details', 'contract_pdf_url']);

      const props = {
        src: url,
        height: '100%',
        width: '100%',
      };

      leaseContent = <iframe title="Lease" {...props} />
    }

    return (
      <Grid className={classes.pdfContainer}>
        {leaseContent}
      </Grid>
    );
  }

  render() {
    return (
      <Grid
        className={this.props.classes.pageContainer}
        container
        justify="center"
        alignItems="center"
        spacing={24}
      >
        {this.renderLeasePDF()}
      </Grid>
    );
  }
}

SignLease.defaultProps = {
  error: null,
  lease: null,
  loaded: false,
  title: 'Sign Lease',
};

SignLease.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.object,
  lease: PropTypes.object,
  loaded: PropTypes.bool,
  title: PropTypes.string,
  ...routePropTypes,
};

const mapStateToProps = (state) => ({
  error: getError(state),
  lease: getLease(state),
  loaded: getLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    generateLease,
    signLease: signLeaseThunk,
  }, dispatch),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignLease));
