/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const PhotoCard = ({
  alt,
  backgroundImage,
  height,
  paragraphs,
  title,
  width,
}) => (
  <div className="photo-card">
    <div className="photo-card-container photo-slide-transition allow-scroll">
      <img
        src={backgroundImage}
        loading="lazy"
        alt={alt}
        className="front-photo"
        height={height}
        width={width}
      />

      <div className="photo-card-front">
        <div className="photo-card-front-container">
          <h2 style={{ textAlign: 'center' }}>
            <span className="card-txt">{title}</span>
          </h2>
        </div>
      </div>
      <div className="photo-card-back">
        <div className="photo-card-back-container">
          {paragraphs.map((text, index) => (
            <p key={`Paragraph_${index}`} style={{ textAlign: 'center' }}>
              <span className="card-copy">{text}</span>
            </p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

PhotoCard.defaultProps = {
  alt: 'Photo',
  backgroundImage: undefined,
  height: '300',
  paragraphs: [],
  title: '',
  width: '300',
};

PhotoCard.propTypes = {
  alt: PropTypes.string,
  backgroundImage: PropTypes.string,
  height: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.node,
  width: PropTypes.string,
};

export default PhotoCard;
