import React from 'react';
import PropTypes from 'prop-types';
import Isomer, { Shape, Point, Color } from 'isomer';
import './styles.css';

class UnitIcon extends React.Component {
  componentDidMount() {
    const { width, length } = this.props;
    const iso = new Isomer(this.canvas);
    const red = new Color(255, 20, 20);
    const startX = width < length ? -0.3 : 0;
    iso.add(Shape.Prism(Point(startX, 0, 0.3), length * 0.05, width * 0.05, 0.3), red);
  }

  render() {
    return (
      <canvas className="unit-icon" ref={c => { this.canvas = c }} />
    )
  }
}

UnitIcon.propTypes = {
  width: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default UnitIcon;
