import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import Router from './routes';
// import registerServiceWorker from './registerServiceWorker';

import './styles/all.css';

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root'),
);

// registerServiceWorker();

window.icPatronChat.init({
  serverHost: 'https://home-c30.incontact.com',
  bus_no: 4596931,
  poc: '1b99ed93-2722-45fe-b7d9-097448ad9558',
});
