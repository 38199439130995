import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Hidden,
  Tabs,
  Tab,
  Typography,
  withStyles,
} from 'material-ui';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import { Link } from 'react-router-dom';
import UnitCard from './UnitCard';
import Title from '../../components/Title';
import unitSizes from '../../images/unitsizes';

const styles = (theme) => ({
  accordionTitle: {
    color: 'white',
  },
  flexContainer: {
    background: theme.palette.background.light,
  },
  pageContainer: {
    gap: '1em',
  },
  root: {
    borderRadius: '10px 10px 0 0',
    width: '100%',
  },
  tabContainer: {
    border: '1px solid black',
    borderRadius: '10px',
    height: 'max-content',
    marginTop: '8px',
    maxWidth: '1400px',
  },
  tabLabel: {
    fontSize: '20px',
    fontWeight: 700,
    textTransform: 'none',
  },
  title: {
    paddingLeft: '1em',
    paddingRight: '1em',
    maxWidth: '1400px',
  },
  footer: {
    paddingTop: '2em',
  },
});

const UnitSizes = ({ classes, title: pageTitle, units }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const onTabChange = useCallback((evt, newTabIndex) => {
    setTabIndex(newTabIndex);
  }, []);

  const onAccordionChange = useCallback((index, evt, expanded) => {
    setTabIndex(expanded ? index : -1);
  }, []);

  const {
    accordionTitle,
    footer,
    pageContainer,
    tabContainer,
    tabLabel,
    title,
    ...tabClasses
  } = classes;
  return (
    <Grid className={pageContainer} container justify="center">
      <Title className={title}>
        <Typography type="display4">RED DOT STORAGE</Typography>
        <Typography type="display2">SIZE GUIDE</Typography>
      </Title>
      <Title className={title}>
        <Typography type="body1" style={{ fontSize: '19px' }}>Red Dot Storage’s month-to-month <Link className="red" to="/units">self storage spaces</Link> come in a wide array of unit sizes, and can accommodate everything from a few file boxes to an entire home’s worth of belongings. Use our storage unit guide as your visual aid to help figure out the space that fits their needs.</Typography>
      </Title>
      { /* Tab Control (for large display) */ }
      <Hidden smDown>
        <Grid className={tabContainer} container>
          <Tabs
            classes={tabClasses}
            fullWidth
            onChange={onTabChange}
            scrollable
            value={tabIndex}
          >
            {units.map(({ label }) => (
              <Tab classes={{ label: tabLabel }} key={label} label={label} />
            ))}
          </Tabs>
          { tabIndex >= 0 ? <UnitCard {...units[tabIndex]} /> : null }
        </Grid>
      </Hidden>
      { /* Accordion Control (for small display) */ }
      <Hidden mdUp>
        <Grid item xs={12}>
          {
            units.map((unit, index) => (
              <ExpansionPanel
                expanded={index === tabIndex}
                onChange={(...args) => onAccordionChange(index, ...args)}
                key={unit.label}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={`${tabLabel} ${accordionTitle}`} type="body1">{unit.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <UnitCard {...units[index]} orientation="vertical" />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          }
        </Grid>
      </Hidden>
      <Grid className={`footer ${footer}`} container justify="center">
        <Title>
          <Typography className="footerText" type="display2">THIS IS WORRY-FREE SELF STORAGE</Typography>
        </Title>
        <Link to="/selectFacility"><Button color="secondary" raised size="large">Our Locations</Button></Link>
      </Grid>
    </Grid>
  );
};

UnitSizes.defaultProps = {
  title: 'Unit Sizes',
  units: unitSizes,
};

UnitSizes.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  units: PropTypes.arrayOf(PropTypes.shape(UnitCard.propTypes)),
};

export default withStyles(styles)(UnitSizes);
