import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Grid, MuiThemeProvider, Typography } from 'material-ui';
import Rollbar from 'rollbar';

import { history } from './store';

import Auth from './containers/Auth';
import ResetPassword from './containers/Auth/ResetPassword';
import AppWrapper from './containers/AppWrapper';
import Maintenance from './containers/Maintenance';
import Home from './containers/Home';
import MyUnits from './containers/MyUnits';
import UnitDescriptions from './containers/UnitSizes';
import Billing from './containers/Billing';
import BillPay from './containers/Billing/billPay';
import Account from './containers/Account';
import Specials from './containers/Specials';
import SearchMap from './containers/SearchMap';
import Facility from './containers/Facility';
import Reservation from './containers/Reservation';
import LeaseAgreement from './containers/LeaseAgreement';
import SignLease from './containers/LeaseAgreement/signLease';
import FAQs from './containers/FAQ';
import SROA from './containers/SROA';
import ContactUs from './containers/ContactUs';
import SellFacility from './containers/Acquisitons';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Insurance from './containers/Insurance';
import AppCache from './AppCache';
import Theme from './styles/theme';

let rollbar;
if (process.env.REACT_APP_API_ENV) {
  rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_API_ENV,
  });
}

const RouteError = ({ message, style }) => {
  useEffect(() => {
    if (rollbar) {
      rollbar.critical(message, { url: window.location.href });
    }
  }, [message, rollbar, window.location.href]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={24}
      style={style}
    >
      <Grid item xs={12} sm={6}>
        <Typography type="display1" align="center">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
RouteError.defaultProps = {
  style: undefined,
};
RouteError.propTypes = {
  message: PropTypes.string.isRequired,
  style: PropTypes.object,
};

const Router = () => {
  // If path is pointing to a file and we got here, that means file doesn't exist
  const lastDot = window.location.pathname.lastIndexOf('.');
  const content = lastDot >= 0 && window.location.pathname.toLowerCase() !== '/index.html' ? (
    <MuiThemeProvider theme={Theme}>
      <RouteError message="File not found" style={{ height: '100vh' }} />
    </MuiThemeProvider>
  ) : (
    <AppWrapper>
      <Switch>
        {/* Auth Routes */}
        <Route exact path="/" component={Auth} />
        <Route exact path="/maintenance" component={Maintenance} />
        <Route exact path="/resetPassword" component={ResetPassword} />

        {/* Main Routes - Logged In */}
        <Route path="/home" component={Home} />
        <Route path="/units" component={MyUnits} />
        <Route path="/unitSizes" component={UnitDescriptions} />
        <Route path="/protection-plan" component={Insurance} />
        <Route path="/faq-tips" component={FAQs} />
        <Route path="/sroa" component={SROA} />
        <Route path="/contact-us/sell-your-facility" component={SellFacility} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/billing" component={Billing} />
        <Route path="/account" component={Account} />
        <Route path="/specials" component={Specials} />
        <Route path="/selectFacility" component={SearchMap} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/facilities/:facilityId" component={Facility} />
        <Route path="/reservation/:unitId" component={Reservation} />
        <Route path="/lease/:envelopeId" component={LeaseAgreement} />
        <Route path="/sign-lease" component={SignLease} />
        <Route path="/pay-bill" component={BillPay} />

        { /* Default / Invalid Route */ }
        <Route render={() => <RouteError message="Invalid URL" />} />
      </Switch>
    </AppWrapper>
  );

  return (
    <ConnectedRouter history={history}>
      <AppCache>
        {content}
      </AppCache>
    </ConnectedRouter>
  );
};

export default Router;
