import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import GeneralForm from '../../components/Form';
import { confirmAccountThunk } from './reducer';
import { required, minLength, equalsField } from '../../utils/validations';

const minLength8 = minLength(8);
const confirmPassword = equalsField('password');

const defaultFields = [{
  fullWidth: true,
  name: 'token',
  label: 'Confirmation Code',
  required: true,
  validate: [required],
}];

const unmaskedPasswordFields = defaultFields.concat({
  name: 'password',
  label: 'Password',
  autoComplete: 'new-password',
  required: true,
  // Server will return a detailed error message so we only need minimal validation here.
  validate: [required, minLength8],
}, {
  name: 'confirm_password',
  label: 'Confirm Password',
  required: true,
  validate: [required, minLength8, confirmPassword],
});
const maskedPasswordFields = unmaskedPasswordFields.map((field) => (field.name === 'password' || field.name === 'confirm_password' ? { ...field, password: true } : field));

const mapStateToProps = (state, ownProps) => {
  let fields = defaultFields;
  if (ownProps.passwordRequired) {
    fields = ownProps.showPassword ? unmaskedPasswordFields : maskedPasswordFields;
  }

  return {
    autoSubmit: ownProps.initialValues.user_id
      && ownProps.initialValues.token
      && !ownProps.passwordRequired,
    isCreate: true,
    fields,
    shrinkLabels: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators({
    create: confirmAccountThunk,
  }, dispatch);
  if (ownProps.onCancel) actions.cancel = ownProps.onCancel;

  return { actions };
};

// Decorate with redux-form
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'confirmAccountForm',
})(GeneralForm)));

