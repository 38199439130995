import { createSelector } from 'reselect';
import { getAuth } from '../Auth/selectors';
import { getOccupiedUnits } from '../MyUnits/selectors';

export const getVehicles = (state) => getAuth(state).get('vehicles');
export const getAvailableVehicles = createSelector(
  getVehicles,
  getOccupiedUnits,
  (vehicles, units) => vehicles && vehicles.filter((v) => !v.get('occupancy_id') || !units.find(u => (u.get('occupancy_id') || '').toString() === v.get('occupancy_id').toString())),
);
export const getVehicle = (state, id) => (getAuth(state).get('vehicles') || []).find((value) => value.get('id') === id);
export const getVehicleByOccupancyId = (state, id) => (getAuth(state).get('vehicles') || []).find((value) => (value.get('occupancy_id') || '').toString() === id.toString());
export const getCustomerInsurances = (state) => getAuth(state).get('customerInsurance');
export const getCustomerInsurance = (state, id) => (getAuth(state).get('customerInsurance') || []).find((value) => value.get('id') === id);
export const getFetching = (state, type) => getAuth(state).getIn(['fetching', type], false);
export const getLoaded = (state, type) => getAuth(state).getIn(['loaded', type], false);
