import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import reduceReducers from 'reduce-reducers';

import authReducer from './containers/Auth/reducer';
import accountReducer from './containers/Account/reducer';
import appReducer from './containers/AppWrapper/reducer';
import unitsReducer from './containers/MyUnits/reducer';
import billingReducer from './containers/Billing/reducer';
import searchMapReducer from './containers/SearchMap/reducer';
import facilityReducer from './containers/Facility/reducer';
import reservationReducer from './containers/Reservation/reducer';
import leaseReducer from './containers/LeaseAgreement/reducer';

const containersReducer = {
  containers: combineReducers({
    auth: reduceReducers(authReducer, accountReducer),
    appReducer,
    unitsReducer,
    billingReducer,
    searchMapReducer,
    facilityReducer,
    reservationReducer,
    leaseReducer,
    // NOTE: put other app reducers here
  }),
};

const createGlobalReducer = () => (
  combineReducers({
    ...containersReducer,
    route: routerReducer,
    form: formReducer,
  })
);

export default createGlobalReducer;
