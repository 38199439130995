import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Typography, withStyles } from 'material-ui';

const styles = (/* theme */) => ({
  centerBlock: {
    width: '100%',
    textAlign: 'center',
  },
  lastBlock: {
    marginTop: 40,
    textAlign: 'right',
  },
  button: {
    margin: 12,
    padding: '0px 40px',
  },
  bodyText: {
    whiteSpace: 'normal',
  },
});

const ReservationHome = ({
  classes,
  onClick,
  redirectUrl,
  title,
}) => {
  useEffect(() => { document.title = title; }, [title]);

  return (
    <Grid container spacing={24}>
      <Grid item xs={12} className={classes.centerBlock}>
        <Typography type="display2" gutterBottom>
          Welcome to Red Dot Storage
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.centerBlock}>
        <Typography className={classes.bodyText} type="subheading">
          Let&apos;s start by getting to know you. With 3 easy steps you&apos;ll
          be moved into your unit in no time!
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.centerBlock}>
        <Typography className={classes.bodyText} type="subheading">
          To continue, please click the &quot;Next&quot; button below. If you
          would instead like to reserve a unit for a later date, please click
          &quot;Move-In Later&quot;.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.lastBlock}>
        <Button
          color="secondary"
          onClick={() => {
            onClick(redirectUrl, { isReservation: true, skippable: true });
          }}
        >
          Move-In Later
        </Button>
        <Button
          raised
          color="secondary"
          className={classes.button}
          onClick={() => {
            onClick(redirectUrl, { hideReservation: true, skippable: true });
          }}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

ReservationHome.defaultProps = {
  onClick: () => {},
  redirectUrl: 'userInfo',
  title: 'New Reservation',
};

ReservationHome.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  redirectUrl: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(ReservationHome);
