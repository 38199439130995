import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const MapControl = ({
  children,
  map,
  position,
}) => {
  const container = useRef(document.createElement('div'));
  const controlIndex = useRef(-1);

  useEffect(() => {
    if (map && typeof position === 'number' && position >= 0) {
      controlIndex.current = map.controls[position].push(container.current) - 1;
      return () => {
        map.controls[position].removeAt(controlIndex.current);
        controlIndex.current = -1;
      };
    }

    return undefined;
  }, [map, position]);

  return createPortal(children, container.current);
};

MapControl.defaultProps = {
  map: null,
  position: -1,
};

MapControl.propTypes = {
  map: PropTypes.object,
  position: PropTypes.number,
};

export default MapControl;
