import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteField from './autocompleteField';

const AUTO_BRANDS = [
  { label: 'Acura' },
  { label: 'Afeela' },
  { label: 'Alfa Romeo' },
  { label: 'Audi' },
  { label: 'BMW' },
  { label: 'Bentley' },
  { label: 'Buick' },
  { label: 'Cadillac' },
  { label: 'Chevrolet' },
  { label: 'Chrysler' },
  { label: 'Dodge' },
  { label: 'Fiat' },
  { label: 'Fisker' },
  { label: 'Ford' },
  { label: 'GMC' },
  { label: 'Genesis' },
  { label: 'Honda' },
  { label: 'Hyundai' },
  { label: 'Infiniti' },
  { label: 'Jaguar' },
  { label: 'Jeep' },
  { label: 'Kia' },
  { label: 'Land Rover' },
  { label: 'Lexus' },
  { label: 'Lincoln' },
  { label: 'Lotus' },
  { label: 'Lucid' },
  { label: 'Maserati' },
  { label: 'Mazda' },
  { label: 'Mercedes-Benz' },
  { label: 'Mercury' },
  { label: 'Mitsubishi' },
  { label: 'Nissan' },
  { label: 'Polestar' },
  { label: 'Pontiac' },
  { label: 'Porsche' },
  { label: 'Rivian' },
  { label: 'Rolls-Royce' },
  { label: 'Saab' },
  { label: 'Saturn' },
  { label: 'Scion' },
  { label: 'Smart' },
  { label: 'Subaru' },
  { label: 'Suzuki' },
  { label: 'Tesla' },
  { label: 'Toyota' },
  { label: 'VinFast' },
  { label: 'Volkswagon' },
  { label: 'Volvo' },
];

const AutoMakeField = ({ label, ...autocompleteProps }) => (
  <AutocompleteField label={label} suggestions={AUTO_BRANDS} {...autocompleteProps} />
);

AutoMakeField.defaultProps = {
  label: 'Make',
}

AutoMakeField.propTypes = {
  label: PropTypes.string,
};

export default AutoMakeField;
